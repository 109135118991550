import React from 'react'
import { useDispatch } from 'react-redux'

import { useConfirmModal } from 'packages/common'
import { Events, track } from 'packages/wiretap'
import { logInfo } from 'packages/wiretap/logging'

import { isViewingSharedClean } from 'app/fieldapp/components/schedule/schedule.utils'
import { Slugs, useI18n } from 'app/fieldapp/i18n'
import { deleteTaskPhoto } from 'app/fieldapp/store/taskPhotos/actions'
import { fetchVisitById } from 'app/fieldapp/store/visits/actions'
import { useActiveUser } from 'app/fieldapp/utils/hooks/useActiveUser'

export type RequestStatus = 'idle' | 'loading' | 'success' | 'error'

const useTranslations = () => {
  const { t } = useI18n()

  return {
    message: t(Slugs.inspectionPhotoDeleteConfirmMessage),
    title: t(Slugs.deletePhoto),
  }
}

type UseDeletePhoto = {
  deletePhoto: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  deletePhotoStatus: RequestStatus
}

export const useDeletePhoto = (
  taskPhotoId?: string,
  visitId?: string,
): UseDeletePhoto => {
  const dispatch = useDispatch()
  const strings = useTranslations()
  const { delegateUserId, user } = useActiveUser()
  const [status, setStatus] = React.useState<RequestStatus>('idle')

  const handleDeletePhoto = React.useCallback(async () => {
    if (!taskPhotoId || !visitId) return

    /* eslint-disable @typescript-eslint/naming-convention */
    const trackingPayload = {
      clean_id: visitId,
      is_delegate: isViewingSharedClean(),
      task_photo_id: taskPhotoId,
      user_id: user?.id || delegateUserId || 'unknown',
    }
    /* eslint-enable @typescript-eslint/naming-convention */

    try {
      setStatus('loading')
      await dispatch(deleteTaskPhoto(taskPhotoId))
      await dispatch(fetchVisitById(visitId))
      track(Events.fieldAppInspectionDeletePhoto, trackingPayload)
      setStatus('success')
    } catch (err) {
      track(Events.fieldAppInspectionDeletePhotoError, {
        ...trackingPayload,
        error: err.message,
      })

      logInfo('Error deleting TaskPhoto', {
        details: {
          ...trackingPayload,
          error: err.message,
        },
      })

      setStatus('error')
    }
  }, [visitId, taskPhotoId, delegateUserId, dispatch, user?.id])

  const { showModal } = useConfirmModal({
    onConfirm: handleDeletePhoto,
    slugs: {
      message: strings.message,
      title: strings.title,
    },
  })

  /**
   * Displays a confirmation dialog to confirm the deletion of a given image.
   * If confirmed, the image will be deleted! After the image is deleted, we will
   * re-fetch the associated task immediately in order ot update its relationships locally.
   */
  const confirmDeletePhoto = React.useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      event.preventDefault() // prevent the click from bubbling to the <img>

      if (!taskPhotoId || !visitId) return
      if (status === 'loading') return

      showModal()
    },
    [visitId, taskPhotoId, showModal, status],
  )

  return {
    deletePhoto: confirmDeletePhoto,
    deletePhotoStatus: status,
  }
}
