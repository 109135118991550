import styled from '@emotion/styled'
import React, { useState, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Options } from 'react-select'
import { useMount } from 'react-use'

import { Button, useToast } from 'packages/common'
import { useManageConnectedDrawerState } from 'packages/common/src/modals/Drawer/hooks/useManageConnectedDrawerState'
import { RawUser, User } from 'packages/grimoire'
import { useAsyncFnWithReset } from 'packages/utils/hooks'
import { logError } from 'packages/wiretap/logging'

import { Slugs, useI18n } from 'app/fieldapp/i18n'
import { createCoveragePartner } from 'app/fieldapp/store/coveragePartner/actions/createCoveragePartner'
import { ApplicationState } from 'app/fieldapp/store/store'
import { setStandardCoveragePartnerDrawer } from 'app/fieldapp/store/ui/actions'
import { getStandardCoveragePartnerDrawerState } from 'app/fieldapp/store/ui/selectors'
import {
  fetchCoveragePartnerOptions,
  fetchCurrentCoveragePartner,
} from 'app/fieldapp/store/users/actions'
import { getCurrentCoveragePartner } from 'app/fieldapp/store/users/selectors'
import { hydrateRawUser } from 'app/fieldapp/store/users/users.utils'
import { useActiveUser } from 'app/fieldapp/utils/hooks/useActiveUser'

import { StandardCoveragePartnerDrawer } from './StandardCoveragePartnerDrawer'

const St = {
  Container: styled.div``,
}

export type StandardCoveragePartnerDrawerUsersState = {
  selectedUserId: null | string
}

export const StandardCoveragePartnerDrawerContainer: React.FC = React.memo(
  () => {
    const { t } = useI18n()
    const { showToast } = useToast()
    const drawerManager = useManageConnectedDrawerState<ApplicationState>({
      dispatchableToggleDrawerAction: setStandardCoveragePartnerDrawer,
      selector: getStandardCoveragePartnerDrawerState,
    })

    const currentPartner = useSelector(getCurrentCoveragePartner)
    const [selectedUser, setSelectedUser] = useState(currentPartner)

    const { user } = useActiveUser()

    const dispatch = useDispatch()

    const [fetchOptionsState, fetchCoveragePartnerOptionsFn] =
      useAsyncFnWithReset(async () => {
        return dispatch(fetchCoveragePartnerOptions())
      }, [dispatch])

    const [fetchExistingPartnerState, fetchExistingPartnerFn] =
      useAsyncFnWithReset(async () => {
        return dispatch(fetchCurrentCoveragePartner())
      }, [dispatch])

    const [
      createCoveragePartnerState,
      createCoveragePartnerFn,
      createCoveragePartnerResetFn,
    ] = useAsyncFnWithReset(async () => {
      if (!user?.id || !selectedUser?.id) return
      return dispatch(
        createCoveragePartner({
          callbacks: {
            onError: ({ response }) => {
              const errorMessage =
                response.data.errors && response.data.errors.length > 0
                  ? response.data.errors[0].title
                  : t(Slugs.createCoveragePartnerError)
              showToast({
                message: errorMessage,
                toastType: 'danger',
              })

              setSelectedUser(currentPartner)
            },
            onSuccess: () => {
              showToast({
                message: t(Slugs.createCoveragePartnerSuccess),
                toastType: 'success',
              })

              setSelectedUser(selectedUser)
              drawerManager.closeDrawer()
            },
          },
          postData: {
            coveredUserId: user.id,
            coveringUserId: selectedUser.id,
          },
        }),
      )
    }, [
      dispatch,
      drawerManager,
      currentPartner,
      selectedUser,
      showToast,
      t,
      user?.id,
    ])

    useMount(() => {
      fetchCoveragePartnerOptionsFn()
      fetchExistingPartnerFn()
    })

    const hasUserChanged = React.useMemo(() => {
      return selectedUser?.id !== currentPartner?.id
    }, [currentPartner?.id, selectedUser])

    const onSubmit = () => {
      createCoveragePartnerFn()
    }

    const errorState = useMemo(
      () => ({
        createCoveragePartnerError: createCoveragePartnerState.error,
        fetchCoveragePartnerOptionsError: fetchOptionsState.error,
        fetchCurrentCoveragePartnerError: fetchExistingPartnerState.error,
      }),
      [
        fetchOptionsState,
        fetchExistingPartnerState,
        createCoveragePartnerState,
      ],
    )

    const isCreateLoading = createCoveragePartnerState.loading

    const debounceTimer = React.useRef<NodeJS.Timeout | null>(null)

    const loadOptions = (
      inputValue: string,
      callback: (options: Options<User>) => void,
    ): void => {
      // Clear any existing timer
      if (debounceTimer.current) {
        clearTimeout(debounceTimer.current)
      }

      // Set a new timer
      debounceTimer.current = setTimeout(async () => {
        if (inputValue.length >= 3) {
          try {
            const normalizedResult = await dispatch(
              fetchCoveragePartnerOptions(inputValue),
            )
            const usersArray: RawUser[] = Object.values(normalizedResult.user)
            const users = usersArray.map(hydrateRawUser)
            callback(users)
          } catch (error) {
            logError(error)
            callback([])
          }
        } else {
          callback([])
        }
      }, 350)
    }

    return (
      <St.Container>
        <Button
          block={true}
          buttonType={'utility'}
          isLoading={fetchOptionsState.loading}
          onClick={() => drawerManager.openDrawer()}
        >
          {t(Slugs.editCoveragePartner)}
        </Button>
        <StandardCoveragePartnerDrawer
          {...drawerManager}
          errors={errorState}
          hasUserChanged={hasUserChanged}
          isLoading={isCreateLoading}
          onSubmit={onSubmit}
          reset={createCoveragePartnerResetFn}
          selectedUser={selectedUser ?? currentPartner}
          setSelectedUser={setSelectedUser}
          loadOptions={loadOptions}
        />
      </St.Container>
    )
  },
)
