import styled from '@emotion/styled'
import { prop } from 'lodash/fp'
import * as React from 'react'
import { useDispatch } from 'react-redux'

import { Alert, Button, RadioButtons, useToast } from 'packages/common'
import { useI18n } from 'packages/i18n'
import { text } from 'packages/styles'
import { useAsyncFnWithReset } from 'packages/utils/hooks'

import { Slugs } from 'app/fieldapp/i18n'
import { AssignmentRejectionReason } from 'app/fieldapp/store/assignments'
import { rejectAssignment } from 'app/fieldapp/store/assignments/actions'

const useTranslations = () => {
  const { t } = useI18n()

  return {
    cancel: t(Slugs.cancel),
    cleanRejected: t(Slugs.cleanRejected),
    dayOff: t(Slugs.dayOff),
    errorTryAgainLater: t(Slugs.errorTryAgainLater),
    other: t(Slugs.other),
    pleaseLetVacasaKnow: t(Slugs.letVacasaKnowRejection),
    reasonForRejection: t(Slugs.reasonForRejection),
    rejectClean: t(Slugs.rejectClean),
    tooBusy: t(Slugs.tooBusy),
  }
}

const St = {
  ButtonsContainer: styled.div`
    display: flex;
    grid-gap: 24px;
    justify-content: flex-end;
  `,

  ModalContainer: styled.div`
    display: flex;
    flex-direction: column;
    grid-gap: 16px;
  `,

  ModalText: styled.div`
    ${text.bodyRegularDefault};
  `,

  ModalTitle: styled.div`
    ${text.headingCard};
  `,
}

type AssignmentRejectionReasonOption = {
  display: string
  id: AssignmentRejectionReason
}

const useRejectionOptions = (): AssignmentRejectionReasonOption[] => {
  const strings = useTranslations()
  return [
    { display: strings.other, id: 'other' },
    { display: strings.dayOff, id: 'day_off' },
    { display: strings.tooBusy, id: 'too_busy' },
  ]
}

export type ContractorRejectionModalProps = {
  assignmentId: string
  beginClose: () => void
  cleanId: string
}

export const ContractorRejectionModal: React.FC<ContractorRejectionModalProps> =
  React.memo(({ assignmentId, beginClose, cleanId }) => {
    const [selectedReasonId, setSelectedReasonId] =
      React.useState<AssignmentRejectionReason>('other')

    const rejectionOptions = useRejectionOptions()

    const selectedReason = React.useMemo(
      () => rejectionOptions.find(option => option.id === selectedReasonId),
      [rejectionOptions, selectedReasonId],
    )

    const { showToast } = useToast()

    const strings = useTranslations()

    const handleSuccess = React.useCallback(() => {
      showToast({
        message: strings.cleanRejected,
      })

      beginClose()
    }, [beginClose, showToast, strings])

    const dispatch = useDispatch()

    const [rejectAssignmentState, rejectAssignmentFn, resetRejectAssignment] =
      useAsyncFnWithReset(
        async () =>
          dispatch(
            rejectAssignment(
              {
                cleanId,
                id: assignmentId,
                rejectionReason: selectedReasonId,
              },
              {
                onSuccess: handleSuccess,
              },
            ),
          ),
        [assignmentId, cleanId, dispatch, handleSuccess, selectedReasonId],
      )

    const handleRejectClean = React.useCallback(async () => {
      if (!rejectAssignmentState.loading) {
        await rejectAssignmentFn()
      }
    }, [rejectAssignmentFn, rejectAssignmentState])

    return (
      <St.ModalContainer>
        <St.ModalTitle>{strings.reasonForRejection}</St.ModalTitle>
        <St.ModalText>{strings.pleaseLetVacasaKnow}</St.ModalText>
        <RadioButtons
          getOptionLabel={prop('display')}
          getOptionValue={prop('id')}
          options={rejectionOptions}
          onChange={setSelectedReasonId}
          selectedValue={selectedReason}
        />

        {rejectAssignmentState.error && (
          <Alert alertType="danger" onClose={resetRejectAssignment}>
            {strings.errorTryAgainLater}
          </Alert>
        )}

        <St.ButtonsContainer>
          <Button
            buttonType="utility"
            onClick={beginClose}
            disabled={rejectAssignmentState.loading}
          >
            {strings.cancel}
          </Button>
          <Button
            disabled={!!rejectAssignmentState.error}
            onClick={handleRejectClean}
            isLoading={rejectAssignmentState.loading}
          >
            {strings.rejectClean}
          </Button>
        </St.ButtonsContainer>
      </St.ModalContainer>
    )
  })
