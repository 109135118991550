enum Slugs {
  access = 'access',
  accessNotGrantedYet = 'accessNotGrantedYet',
  addAdditionalDetails = 'addAdditionalDetails',
  addComment = 'addComment',
  addCommentError = 'addCommentError',
  addCommentPlaceholder = 'addCommentPlaceholder',
  addCommentSuccess = 'addCommentSuccess',
  addNote = 'addNote',
  addNoteError = 'addNoteError',
  addNoteSuccess = 'addNoteSuccess',
  addPhoto = 'addPhoto',
  addTicketConfirmText = 'addTicketConfirmText',
  addTicketConfirmTitle = 'addTicketConfirmTitle',
  addTicketSuccess = 'addTicketSuccess',
  addTicketToVisit = 'addTicketToVisit',
  addTicketToVisitAndAssign = 'addTicketToVisitAndAssign',
  addTicketToVisitAndReassign = 'addTicketToVisitAndReassign',
  addToMyVisit = 'addToMyVisit',
  addToNewVisit = 'addToNewVisit',
  addVisit = 'addVisit',
  addVisitError = 'addVisitError',
  addVisitInspectionSubtitle = 'addVisitInspectionSubtitle',
  addVisitSuccessMessage = 'addVisitSuccessMessage',
  additionalDetails = 'additionalDetails',
  additionalHomeInfo = 'additionalHomeInfo',
  additionalInstructions = 'additionalInstructions',
  address = 'address',
  adminLinkAlert = 'adminLinkAlert',
  alarmCode = 'alarmCode',
  all = 'all',
  allCleans = 'allCleans',
  allDeepCleanTasksDone = 'allDeepCleanTasksDone',
  allTasksCompletedHeader = 'allTasksCompletedHeader',
  allTasksCompletedText = 'allTasksCompletedText',
  allTickets = 'allTickets',
  alreadyAddedToTickets = 'alreadyAddedToTickets',
  alreadyAddedToVisit = 'alreadyAddedToVisit',
  anErrorOccurred = 'anErrorOccurred',
  anErrorOccurredLong = 'anErrorOccurredLong',
  applyButton = 'applyButton',
  areYouSure = 'areYouSure',
  assignToMyself = 'assignToMyself',
  assignedCoverage = 'assignedCoverage',
  assignedTo = 'assignedTo',
  assignmentPrefDefault = 'assignmentPrefDefault',
  assignmentPreference = 'assignmentPreference',
  authErrorBody = 'authErrorBody',
  availabilityIncorrect = 'availabilityIncorrect',
  availabilityPresets = 'availabilityPresets',
  b2bCheckIn = 'b2bCheckIn',
  back = 'back',
  backup = 'backup',
  badgeB2b = 'badgeB2b',
  badgeEarly = 'badgeEarly',
  badgeLate = 'badgeLate',
  bath = 'bath',
  bathroom = 'bathroom',
  bed = 'bed',
  bedroom = 'bedroom',
  bedroomQuantity = 'bedroomQuantity',
  by = 'by',
  cancel = 'cancel',
  cannotChangeAssignedCoverage = 'cannotChangeAssignedCoverage',
  cannotCreateTicket = 'cannotCreateTicket',
  cannotLoadDate = 'cannotLoadDate',
  cannotMakeChangesWhileOffline = 'cannotMakeChangesWhileOffline',
  changeYourAvailability = 'changeYourAvailability',
  changeYourSchedule = 'changeYourSchedule',
  changedToPostStayClean = 'changedToPostStayClean',
  channels = 'channels',
  checkHoursTracker = 'checkHoursTracker',
  checkIn = 'checkIn',
  checkedOutDate = 'checkedOutDate',
  checksInDate = 'checksInDate',
  checksOutDate = 'checksOutDate',
  chooseAnOption = 'chooseAnOption',
  chooseDays = 'chooseDays',
  cleanComplete = 'cleanComplete',
  cleanId = 'cleanId',
  cleanIdForUkg = 'cleanIdForUkg',
  cleanInProgress = 'cleanInProgress',
  cleanNotFoundError = 'cleanNotFoundError',
  cleanNotStarted = 'cleanNotStarted',
  cleanNotYetComplete = 'cleanNotYetComplete',
  cleanNotesTitle = 'cleanNotesTitle',
  cleanReassigned = 'cleanReassigned',
  cleanRejected = 'cleanRejected',
  cleanType = 'cleanType',
  cleans = 'cleans',
  comment = 'comment',
  comments = 'comments',
  commentsWillBePosted = 'commentsWillBePosted',
  complete = 'complete',
  completeCleanModalFooter = 'completeCleanModalFooter',
  completeCleanModalFooterAlt = 'completeCleanModalFooterAlt',
  completeCleanModalText = 'completeCleanModalText',
  completeCleanModalTextContractor = 'completeCleanModalTextContractor',
  completeCleanModalTitle = 'completeCleanModalTitle',
  completeTicketModalText = 'completeTicketModalText',
  completeTicketModalTitle = 'completeTicketModalTitle',
  completed = 'completed',
  confirm = 'confirm',
  confirmDelete = 'confirmDelete',
  confirmed = 'confirmed',
  copied = 'copied',
  copy = 'copy',
  copyFailed = 'copyFailed',
  copyLink = 'copyLink',
  coverageDefaultModalMessage = 'coverageDefaultModalMessage',
  coverageDefaultModalTitle = 'coverageDefaultModalTitle',
  coveragePartner = 'coveragePartner',
  coverageRepeatTooltipText = 'coverageRepeatTooltipText',
  coveredBy = 'coveredBy',
  coveringFor = 'coveringFor',
  createATicketDefaultDispositionDisplayValue = 'createATicketDefaultDispositionDisplayValue',
  createATicketDispositionErrorMessage = 'createATicketDispositionErrorMessage',
  createAvailabilityError = 'createAvailabilityError',
  createAvailabilitySuccess = 'createAvailabilitySuccess',
  createCoveragePartnerError = 'createCoveragePartnerError',
  createCoveragePartnerSuccess = 'createCoveragePartnerSuccess',
  createCrossCoverageFailure = 'createCrossCoverageFailure',
  createCrossCoverageSuccess = 'createCrossCoverageSuccess',
  createMaintenanceTicketHeaderWithUnit = 'createMaintenanceTicketHeaderWithUnit',
  createMaintenanceTicketHeaderWithoutUnit = 'createMaintenanceTicketHeaderWithoutUnit',
  createTicket = 'createTicket',
  createTicketMessageFailure = 'createTicketMessageFailure',
  createTicketSuccessMessage = 'createTicketSuccessMessage',
  createVisitErrorOtherUserVisit = 'createVisitErrorOtherUserVisit',
  createVisitErrorUnitOccupied = 'createVisitErrorUnitOccupied',
  createVisitErrorUserHasVisit = 'createVisitErrorUserHasVisit',
  created = 'created',
  currentAvailability = 'currentAvailability',
  currentAvailabilityDescription = 'currentAvailabilityDescription',
  currentRes = 'currentRes',
  day = 'day',
  dayOff = 'dayOff',
  days = 'days',
  deep = 'deep',
  deepCleanComplete = 'deepCleanComplete',
  deepCleanDue = 'deepCleanDue',
  deepCleanRevertEffect1 = 'deepCleanRevertEffect1',
  deepCleanRevertEffect2 = 'deepCleanRevertEffect2',
  deepCleanRevertEffect3 = 'deepCleanRevertEffect3',
  deepCleanSkipSuccess = 'deepCleanSkipSuccess',
  delete = 'delete',
  deleteAvailabilityError = 'deleteAvailabilityError',
  deleteAvailabilitySuccess = 'deleteAvailabilitySuccess',
  deleteCompletedVisitMessage = 'deleteCompletedVisitMessage',
  deletePhoto = 'deletePhoto',
  deleteStartedVisitMessage = 'deleteStartedVisitMessage',
  deleteSubmissionTextBlock = 'deleteSubmissionTextBlock',
  deleteSubmissionTitle = 'deleteSubmissionTitle',
  deleteSystemVisitMessage = 'deleteSystemVisitMessage',
  deleteVisitConfirmationMessage = 'deleteVisitConfirmationMessage',
  deleteXCovFailure = 'deleteXCovFailure',
  deleteXCovSuccess = 'deleteXCovSuccess',
  description = 'description',
  detailsTicketBody = 'detailsTicketBody',
  dog = 'dog',
  dogsCount = 'dogsCount',
  double = 'double',
  drive = 'drive',
  driveTimeNotesLabel = 'driveTimeNotesLabel',
  driveTimerCleanAProperty = 'driveTimerCleanAProperty',
  driveTimerHome = 'driveTimerHome',
  driveTimerInspection = 'driveTimerInspection',
  driveTimerMaintenance = 'driveTimerMaintenance',
  driveTimerOffSiteLaundry = 'driveTimerOffSiteLaundry',
  driveTimerSupplies = 'driveTimerSupplies',
  driveTo = 'driveTo',
  driving = 'driving',
  due = 'due',
  editCoveragePartner = 'editCoveragePartner',
  editCoverageRequest = 'editCoverageRequest',
  editStandardAvailability = 'editStandardAvailability',
  editType = 'editType',
  end = 'end',
  error000501 = 'error000501',
  error000502 = 'error000502',
  error0C0501 = 'error0C0501',
  error0C0502 = 'error0C0502',
  error0C0503 = 'error0C0503',
  error0C0504 = 'error0C0504',
  error0C0505 = 'error0C0505',
  error0D0101 = 'error0D0101',
  error0D0501 = 'error0D0501',
  error0D0502 = 'error0D0502',
  error0D0503 = 'error0D0503',
  error0D0504 = 'error0D0504',
  errorTicketNotFound = 'errorTicketNotFound',
  errorTryAgainLater = 'errorTryAgainLater',
  eventAlreadyStarted = 'eventAlreadyStarted',
  eventEnd = 'eventEnd',
  eventRepeats = 'eventRepeats',
  eventStart = 'eventStart',
  eventStartEndTimeErrorMessage = 'eventStartEndTimeErrorMessage',
  everyDay = 'everyDay',
  failedToRetrieve = 'failedToRetrieve',
  failedToRetrieveSmartLock = 'failedToRetrieveSmartLock',
  fetchAvailabilityError = 'fetchAvailabilityError',
  fetchCoverageError = 'fetchCoverageError',
  fetchCoveragePartnerOptionsError = 'fetchCoveragePartnerOptionsError',
  fetchCurrentCoveragePartnerError = 'fetchCurrentCoveragePartnerError',
  firstOccurrence = 'firstOccurrence',
  flaggedItems = 'flaggedItems',
  freezeWindowBody = 'freezeWindowBody',
  fri = 'fri',
  friday = 'friday',
  fridayF = 'fridayF',
  full = 'full',
  futon = 'futon',
  gateCode = 'gateCode',
  genericErrorMsg = 'genericErrorMsg',
  genericHoursTrackerMsg = 'genericHoursTrackerMsg',
  genericNotesLabel = 'genericNotesLabel',
  guest = 'guest',
  guestsCount = 'guestsCount',
  half = 'half',
  here = 'here',
  hi = 'hi',
  homeInfo = 'homeInfo',
  homeIsCleaned = 'homeIsCleaned',
  hotTub = 'hotTub',
  hours = 'hours',
  hoursSubmitted = 'hoursSubmitted',
  housekeepingManager = 'housekeepingManager',
  iAgree = 'iAgree',
  inProgress = 'inProgress',
  incompleteTasksWarningHeader = 'incompleteTasksWarningHeader',
  incompleteTasksWarningText = 'incompleteTasksWarningText',
  inspection = 'inspection',
  inspectionCleanNotStarted = 'inspectionCleanNotStarted',
  inspectionCompleteInspection = 'inspectionCompleteInspection',
  inspectionCompletedText = 'inspectionCompletedText',
  inspectionCompletedTitle = 'inspectionCompletedTitle',
  inspectionDecline = 'inspectionDecline',
  inspectionErrorCompleteInspection = 'inspectionErrorCompleteInspection',
  inspectionErrorToggleItem = 'inspectionErrorToggleItem',
  inspectionHasCompleteClean = 'inspectionHasCompleteClean',
  inspectionHasCompleteVisit = 'inspectionHasCompleteVisit',
  inspectionHasNoActiveTimer = 'inspectionHasNoActiveTimer',
  inspectionIncompleteMessage = 'inspectionIncompleteMessage',
  inspectionNeedMoreHelp1 = 'inspectionNeedMoreHelp1',
  inspectionNeedMoreHelp2 = 'inspectionNeedMoreHelp2',
  inspectionNotComplete = 'inspectionNotComplete',
  inspectionNotStarted = 'inspectionNotStarted',
  inspectionPhotoDeleteConfirmMessage = 'inspectionPhotoDeleteConfirmMessage',
  inspectionPhotoDeleteError = 'inspectionPhotoDeleteError',
  inspectionPhotoProcessing = 'inspectionPhotoProcessing',
  inspectionPhotoUploadError = 'inspectionPhotoUploadError',
  inspectionPhotoUploadTooltip = 'inspectionPhotoUploadTooltip',
  inspectionStepThreeText = 'inspectionStepThreeText',
  inspectionStepUploadImages = 'inspectionStepUploadImages',
  inspectionStepVisuallyCheck = 'inspectionStepVisuallyCheck',
  inspectionVisitNotStarted = 'inspectionVisitNotStarted',
  invalidImage = 'invalidImage',
  irreversibleChangeWarning = 'irreversibleChangeWarning',
  isCoveringStatus = 'isCoveringStatus',
  isRequested = 'isRequested',
  items = 'items',
  king = 'king',
  lastCompleted = 'lastCompleted',
  lastOccurrence = 'lastOccurrence',
  lastUpdated = 'lastUpdated',
  laundry = 'laundry',
  letUsKnowCreateATicket = 'letUsKnowCreateATicket',
  letVacasaKnowRejection = 'letVacasaKnowRejection',
  listing = 'listing',
  loading = 'loading',
  lockbox = 'lockbox',
  logout = 'logout',
  markDone = 'markDone',
  markVisitDone = 'markVisitDone',
  maxOccupancy = 'maxOccupancy',
  midStay = 'midStay',
  mon = 'mon',
  monday = 'monday',
  mondayM = 'mondayM',
  moreDetailsOnHome = 'moreDetailsOnHome',
  moreTickets = 'moreTickets',
  myAvailability = 'myAvailability',
  myStandardAvailability = 'myStandardAvailability',
  myTickets = 'myTickets',
  nameRegion = 'nameRegion',
  network = 'network',
  newCoverage = 'newCoverage',
  newCrossCoverageRequest = 'newCrossCoverageRequest',
  nextDay = 'nextDay',
  nextRes = 'nextRes',
  no = 'no',
  noActivity = 'noActivity',
  noAssignedTickets = 'noAssignedTickets',
  noAttachedTickets = 'noAttachedTickets',
  noCleans = 'noCleans',
  noComments = 'noComments',
  noCoveragePartnerOptionsFound = 'noCoveragePartnerOptionsFound',
  noCoveragePartnerSelected = 'noCoveragePartnerSelected',
  noNotesRequiredToSave = 'noNotesRequiredToSave',
  noOpenTickets = 'noOpenTickets',
  noPhoto = 'noPhoto',
  noResults = 'noResults',
  noneOfThese = 'noneOfThese',
  noneScheduled = 'noneScheduled',
  notAddedToTickets = 'notAddedToTickets',
  notCompleted = 'notCompleted',
  notStarted = 'notStarted',
  notYet = 'notYet',
  notePlaceholder = 'notePlaceholder',
  notes = 'notes',
  off = 'off',
  offlineTimersBanner = 'offlineTimersBanner',
  offlineTimersSubmitNote = 'offlineTimersSubmitNote',
  oldestInspectionRanking = 'oldestInspectionRanking',
  onCall = 'onCall',
  oneTime = 'oneTime',
  oopsChangesNotSaved = 'oopsChangesNotSaved',
  openMap = 'openMap',
  openTicketUrgencyPoints = 'openTicketUrgencyPoints',
  optional = 'optional',
  other = 'other',
  otherNotesPlaceholderText = 'otherNotesPlaceholderText',
  otherTaskToStart = 'otherTaskToStart',
  otherTimeNotesLabel = 'otherTimeNotesLabel',
  otherTimerAdmin = 'otherTimerAdmin',
  otherTimerEmployeeMeeting = 'otherTimerEmployeeMeeting',
  otherTimerOfficeCleaning = 'otherTimerOfficeCleaning',
  otherTimerRunning = 'otherTimerRunning',
  otherTimerScheduling = 'otherTimerScheduling',
  overdueTooltipText = 'overdueTooltipText',
  owner = 'owner',
  ownerArrival = 'ownerArrival',
  pageNotFound = 'pageNotFound',
  partner = 'partner',
  password = 'password',
  paused = 'paused',
  pleaseLetUsKnow = 'pleaseLetUsKnow',
  possibleTicketsBody = 'possibleTicketsBody',
  possibleTicketsTitle = 'possibleTicketsTitle',
  postStay = 'postStay',
  postStayCleanComplete = 'postStayCleanComplete',
  predictedCleanTime = 'predictedCleanTime',
  previousInspectionDate = 'previousInspectionDate',
  previousRes = 'previousRes',
  primary = 'primary',
  priorClean = 'priorClean',
  priorReservation = 'priorReservation',
  privacyNotice = 'privacyNotice',
  privacyNoticeBody = 'privacyNoticeBody',
  privacyNoticeTitle = 'privacyNoticeTitle',
  profile = 'profile',
  queen = 'queen',
  rate = 'rate',
  ratings = 'ratings',
  reasonForDriving = 'reasonForDriving',
  reasonForEdit = 'reasonForEdit',
  reasonForRejection = 'reasonForRejection',
  reject = 'reject',
  rejectClean = 'rejectClean',
  rejectDelete = 'rejectDelete',
  removeVisit = 'removeVisit',
  removeVisitError = 'removeVisitError',
  removeVisitSuccess = 'removeVisitSuccess',
  repeatUntilInclusive = 'repeatUntilInclusive',
  repeating = 'repeating',
  repeatingEvents = 'repeatingEvents',
  requestTimeOff = 'requestTimeOff',
  requestedBy = 'requestedBy',
  requestedCoverage = 'requestedCoverage',
  required = 'required',
  resWithin48Hours = 'resWithin48Hours',
  reservation = 'reservation',
  reviewAccuracyRating = 'reviewAccuracyRating',
  reviewCheckinRating = 'reviewCheckinRating',
  reviewCleanlinessRating = 'reviewCleanlinessRating',
  reviewComfortRating = 'reviewComfortRating',
  reviewCommunicationRating = 'reviewCommunicationRating',
  reviewDate = 'reviewDate',
  reviewFacilitiesRating = 'reviewFacilitiesRating',
  reviewFiltersButton = 'reviewFiltersButton',
  reviewFiltersByChannelTitle = 'reviewFiltersByChannelTitle',
  reviewFiltersByRatingTitle = 'reviewFiltersByRatingTitle',
  reviewLocationRating = 'reviewLocationRating',
  reviewNoAttached = 'reviewNoAttached',
  reviewOnlineListingRating = 'reviewOnlineListingRating',
  reviewOverallRating = 'reviewOverallRating',
  reviewPropertyConditionRating = 'reviewPropertyConditionRating',
  reviewServiceRating = 'reviewServiceRating',
  reviewStaffRating = 'reviewStaffRating',
  reviewTitle = 'reviewTitle',
  reviewValueRating = 'reviewValueRating',
  reviewWifiRating = 'reviewWifiRating',
  reviews = 'reviews',
  routine = 'routine',
  sameDay = 'sameDay',
  sat = 'sat',
  saturday = 'saturday',
  saturdaySA = 'saturdaySA',
  save = 'save',
  savedOffline = 'savedOffline',
  savingAsPostStayDescription = 'savingAsPostStayDescription',
  schedule = 'schedule',
  searchUnitNameOrCode = 'searchUnitNameOrCode',
  seeAProblem = 'seeAProblem',
  seeAllTicketsForUnit = 'seeAllTicketsForUnit',
  seeAnIssue = 'seeAnIssue',
  seeDetailViewForSystemAssignee = 'seeDetailViewForSystemAssignee',
  selectAnOption = 'selectAnOption',
  selectCoveragePartner = 'selectCoveragePartner',
  selectTimer = 'selectTimer',
  selfInspectionNotCompleted = 'selfInspectionNotCompleted',
  severity = 'severity',
  severityLow = 'severityLow',
  severityMedium = 'severityMedium',
  severityPlanned = 'severityPlanned',
  severityPriorToNextGuest = 'severityPriorToNextGuest',
  severityToday = 'severityToday',
  severityUrgent = 'severityUrgent',
  share = 'share',
  shareCleanBody = 'shareCleanBody',
  shareCleanButton = 'shareCleanButton',
  sharedCleanExpiredBody = 'sharedCleanExpiredBody',
  sharedCleanExpiredTitle = 'sharedCleanExpiredTitle',
  shortDescription = 'shortDescription',
  showLess = 'showLess',
  showMore = 'showMore',
  smartLock = 'smartLock',
  sofaBed = 'sofaBed',
  staffAndOwner = 'staffAndOwner',
  staffOnly = 'staffOnly',
  staffOnlyDefault = 'staffOnlyDefault',
  standardAvailabilityDeleteInfo = 'standardAvailabilityDeleteInfo',
  standardCoverageExplanation = 'standardCoverageExplanation',
  start = 'start',
  startATicket = 'startATicket',
  startDate = 'startDate',
  startTime = 'startTime',
  startTimeMustBeBeforeEndTime = 'startTimeMustBeBeforeEndTime',
  startTyping = 'startTyping',
  stepWithNumber = 'stepWithNumber',
  stop = 'stop',
  submit = 'submit',
  submitHours = 'submitHours',
  submitted = 'submitted',
  sun = 'sun',
  sunday = 'sunday',
  sundaySU = 'sundaySU',
  systemAssigned = 'systemAssigned',
  systemAssignee = 'systemAssignee',
  systemAssigneeAlert = 'systemAssigneeAlert',
  systemAssigneeInfoAlert = 'systemAssigneeInfoAlert',
  tasks = 'tasks',
  teamClean = 'teamClean',
  templateDetails = 'templateDetails',
  thisHomeIs = 'thisHomeIs',
  thur = 'thur',
  thursday = 'thursday',
  thursdayTH = 'thursdayTH',
  ticketCantBeCompletedWarning = 'ticketCantBeCompletedWarning',
  ticketCompleteError = 'ticketCompleteError',
  ticketCompleteSuccess = 'ticketCompleteSuccess',
  ticketReassigned = 'ticketReassigned',
  ticketSeverityMedium = 'ticketSeverityMedium',
  ticketSeverityPriorNextGuest = 'ticketSeverityPriorNextGuest',
  ticketSeverityUrgent = 'ticketSeverityUrgent',
  ticketStatusActionAccept = 'ticketStatusActionAccept',
  ticketStatusActionComplete = 'ticketStatusActionComplete',
  tickets = 'tickets',
  ticketsIncompleteMessage = 'ticketsIncompleteMessage',
  ticketsOnThisUnit = 'ticketsOnThisUnit',
  timeOfTextBlock = 'timeOfTextBlock',
  timeOffBlock = 'timeOffBlock',
  timelineIsIn = 'timelineIsIn',
  timerChangeErrorEndBefore = 'timerChangeErrorEndBefore',
  timerChangeErrorFuture = 'timerChangeErrorFuture',
  timerChangeErrorStartAfter = 'timerChangeErrorStartAfter',
  timerSavedOffline = 'timerSavedOffline',
  title = 'title',
  today = 'today',
  todo = 'todo',
  tooBusy = 'tooBusy',
  toolTipB2b = 'toolTipB2b',
  toolTipEarly = 'toolTipEarly',
  toolTipLate = 'toolTipLate',
  toolTipPotentialB2b = 'toolTipPotentialB2b',
  trackMileage = 'trackMileage',
  training = 'training',
  tryAgain = 'tryAgain',
  tue = 'tue',
  tuesday = 'tuesday',
  tuesdayTU = 'tuesdayTU',
  twin = 'twin',
  typeToSearch = 'typeToSearch',
  unassigned = 'unassigned',
  unit = 'unit',
  unitInfo = 'unitInfo',
  unitIsVacant = 'unitIsVacant',
  unitManager = 'unitManager',
  unitOccupied = 'unitOccupied',
  unitSearchNoResults = 'unitSearchNoResults',
  unknown = 'unknown',
  upcomingAvailability = 'upcomingAvailability',
  upcomingAvailabilityDescription = 'upcomingAvailabilityDescription',
  upcomingCoverage = 'upcomingCoverage',
  upcomingCrossCoveragesEmpty = 'upcomingCrossCoveragesEmpty',
  upcomingCrossCoveragesError = 'upcomingCrossCoveragesError',
  update = 'update',
  updateAvailability = 'updateAvailability',
  updateAvailabilityError = 'updateAvailabilityError',
  updateAvailabilitySuccess = 'updateAvailabilitySuccess',
  updateXCovFailure = 'updateXCovFailure',
  updateXCovSuccess = 'updateXCovSuccess',
  updatesAvailable = 'updatesAvailable',
  updating = 'updating',
  vacancyUnknown = 'vacancyUnknown',
  view = 'view',
  viewHome = 'viewHome',
  viewOpenTickets = 'viewOpenTickets',
  viewReservation = 'viewReservation',
  viewTicket = 'viewTicket',
  visibility = 'visibility',
  visit = 'visit',
  visitCardVPSHeader = 'visitCardVPSHeader',
  visitInspectionHasNoActiveTimer = 'visitInspectionHasNoActiveTimer',
  visitNeededForAccess = 'visitNeededForAccess',
  visitPriorityScore = 'visitPriorityScore',
  visitTicketAttach = 'visitTicketAttach',
  visitTicketFilterAlertText = 'visitTicketFilterAlertText',
  visitTicketFilterToggleText = 'visitTicketFilterToggleText',
  visitTicketReassign = 'visitTicketReassign',
  visitTicketSubtitle = 'visitTicketSubtitle',
  visitTicketTitle = 'visitTicketTitle',
  visitUpdateError = 'visitUpdateError',
  visitUpdateSuccess = 'visitUpdateSuccess',
  visits = 'visits',
  wed = 'wed',
  wednesday = 'wednesday',
  wednesdayW = 'wednesdayW',
  whatNeedsAttention = 'whatNeedsAttention',
  wifi = 'wifi',
  willThisEventRepeat = 'willThisEventRepeat',
  yes = 'yes',
  you = 'you',
  youAreOncall = 'youAreOncall',
  yourRegion = 'yourRegion',
  youreBackOnline = 'youreBackOnline',
  youreOffline = 'youreOffline',
}

export default Slugs
