import { createSelector } from 'reselect'

import { ApplicationState } from 'app/fieldapp/store/store'

import { StandardCoveragePartnerDrawerState } from '../ui.types'

export const getStandardCoveragePartnerDrawerState = createSelector(
  (state: ApplicationState) => state.ui.drawers.standardCoveragePartnerDrawer,
  (standardCoveragePartnerDrawerState: StandardCoveragePartnerDrawerState) =>
    standardCoveragePartnerDrawerState,
)
