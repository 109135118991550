import { createDateObject, isAfter, isBefore } from 'packages/utils/dateHelpers'

import { ApplicationState } from '../../store'
import { Coverage } from '../coverage.types'
import { hydrateRawCoverage } from '../coverage.utils'

export const getCurrentCoverage = (
  state: ApplicationState,
): Coverage | undefined => {
  const rawCoverage =
    state.coverage.data &&
    Object.values(state.coverage.data).find(
      coverage =>
        isBefore(coverage.attributes.start, createDateObject()) &&
        isAfter(coverage.attributes.end, createDateObject()),
    )

  return rawCoverage && hydrateRawCoverage(rawCoverage)
}
