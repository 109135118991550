import { useDispatch, useSelector } from 'react-redux'
import { useAsyncFn, useMount } from 'react-use'

import { useAsyncFnWithReset } from 'packages/utils/hooks'

import { fetchCrossCoverageById } from 'app/fieldapp/store/crossCoverage/actions/fetchCrossCoverageById'
import { getCrossCoverageById } from 'app/fieldapp/store/crossCoverage/selectors/getCrossCoverageById'
import { ApplicationState } from 'app/fieldapp/store/store'
import {
  fetchCoveragePartnerOptions,
  fetchCurrentCoveragePartner,
} from 'app/fieldapp/store/users/actions'
import {
  getCurrentCoveragePartner,
  getUserById,
} from 'app/fieldapp/store/users/selectors'

import { CrossCoverageFormState } from './CrossCoverageForm.reducer'
import { transformCrossCoverageToFormState } from './transformCrossCoverageToFormState'

type UseExistingCoverageLoader = {
  loading: boolean
  state: CrossCoverageFormState | undefined
}

export const useExistingCoverageLoader = (
  crossCoverageId: string | undefined,
  systemAssignedUserId?: string,
): UseExistingCoverageLoader => {
  const dispatch = useDispatch()
  const currentCoveragePartner = useSelector(getCurrentCoveragePartner)
  const crossCoverage = useSelector((state: ApplicationState) => {
    if (!crossCoverageId) return null
    return getCrossCoverageById(state, crossCoverageId)
  })

  const [fetchCoveragePartnerOptionsState, fetchCoveragePartnerOptionsFn] =
    useAsyncFn(async () => {
      return dispatch(fetchCoveragePartnerOptions())
    })

  const [fetchCurrentCoveragePartnerState, fetchCurrentCoveragePartnerFn] =
    useAsyncFnWithReset(async () => {
      return dispatch(fetchCurrentCoveragePartner())
    }, [dispatch])

  const [fetchCrossCoverageByIdState, fetchCrossCoverageByIdFn] =
    useAsyncFn(async () => {
      if (!crossCoverageId) return
      dispatch(fetchCrossCoverageById(crossCoverageId))
    }, [crossCoverageId])

  useMount(() => {
    fetchCoveragePartnerOptionsFn()
    fetchCurrentCoveragePartnerFn()
    fetchCrossCoverageByIdFn()
  })

  const isLoadingData =
    fetchCoveragePartnerOptionsState.loading ||
    fetchCurrentCoveragePartnerState.loading ||
    fetchCrossCoverageByIdState.loading

  const assignee = useSelector((state: ApplicationState) =>
    crossCoverage?.userCoveringId
      ? getUserById(state, crossCoverage.userCoveringId)
      : undefined,
  )

  const state =
    crossCoverage && assignee
      ? transformCrossCoverageToFormState({
          assignee,
          crossCoverage,
          standardPartnerId: currentCoveragePartner?.id,
          systemAssignedUserId,
        })
      : undefined

  return {
    loading: isLoadingData,
    state,
  }
}
