import { OptimizelyProvider } from '@optimizely/react-sdk'
import React from 'react'

import {
  SuperModalProvider,
  ToastCustomContainer,
  ModalProvider,
  Alert,
  Loader,
  Banner,
} from 'packages/common'
import { createOptimizelyInstance, decodeUserId } from 'packages/optimizely'
import { SegmentTracker } from 'packages/wiretap/src/tracking/SegmentTracker'

import {
  reducer as timersReducer,
  initialState as initialTimersState,
} from 'app/fieldapp/components/timers/state/timers.reducer'
import { Slugs, useI18n } from 'app/fieldapp/i18n'
import { fanOutDataBatchRequests } from 'app/fieldapp/store/app/actions/'
import { getBanner } from 'app/fieldapp/store/ui/selectors/getBanner'

import { getFanOutBatchRequests } from '../store/app/selectors'
import { getAuthError, getAuthToken } from '../store/auth/selectors'
import { useAppDispatch, useAppSelector } from '../store/hooks'
import { clearBanner, setBanner } from '../store/ui/actions'
import { fetchCurrentUser } from '../store/users/actions'
import { getActiveUser } from '../store/users/selectors'
import { useOfflineData } from '../store/utils'
import { ImpersonationPanel } from './admin/components/ImpersonationPanel'
import { NavTabWrapper, OfflineTimersBannerContainer } from './core'
import { usePrivacyDisclosureModal } from './core/components/PrivacyDisclosureModal'
import FieldAppRouter from './FieldApp.router'
import { isViewingSharedClean } from './schedule/schedule.utils'
import { ConnectedTimers } from './timers'
import { TimersContext } from './timers/state/timers.context'

const AuthErrorAlert = () => {
  const { t } = useI18n()

  return (
    <Alert alertType={'danger'}>
      <div>{t(Slugs.authErrorBody)}</div>
    </Alert>
  )
}

const optimizelyKey = process.env.REACT_APP_OPTIMIZELY_SDK_KEY || ''
const optimizely = createOptimizelyInstance(optimizelyKey)

export type FieldAppRootProps = {
  initialUrl: string
}

export const FieldAppRoot: React.FC<FieldAppRootProps> = ({ initialUrl }) => {
  usePrivacyDisclosureModal()
  const dispatch = useAppDispatch()
  const { t } = useI18n()
  const { reloadOfflineData } = useOfflineData()
  const authError = useAppSelector(getAuthError)
  const fanOutDataBatchRequestsState = useAppSelector(getFanOutBatchRequests)
  const activeUser = useAppSelector(getActiveUser)
  const isSharedClean = isViewingSharedClean()
  const token = useAppSelector(getAuthToken)
  const banner = useAppSelector(getBanner)
  const monolithUserId = activeUser?.monolithUserId || decodeUserId(token)

  const [timersState, timersDispatch] = React.useReducer(
    timersReducer,
    initialTimersState,
  )

  const timersContextValue = React.useMemo(
    () => ({ dispatch: timersDispatch, state: timersState }),
    [timersState],
  )

  const fetchUserAndData = React.useCallback(async () => {
    await dispatch(fetchCurrentUser())
    await dispatch(fanOutDataBatchRequests())
  }, [dispatch])

  React.useEffect(() => {
    if (isSharedClean) return
    fetchUserAndData()
  }, [isSharedClean, fetchUserAndData])

  React.useEffect(() => {
    if (fanOutDataBatchRequestsState !== 'fulfilled') return
    reloadOfflineData()
  }, [fanOutDataBatchRequestsState, reloadOfflineData])

  React.useEffect(() => {
    if (fanOutDataBatchRequestsState !== 'rejected') return
    dispatch(setBanner({ message: t(Slugs.anErrorOccurredLong) }))
  }, [dispatch, fanOutDataBatchRequestsState, t])

  if (authError) {
    return <AuthErrorAlert />
  }

  if (!activeUser && !isSharedClean) {
    return <Loader />
  }

  return (
    <OptimizelyProvider
      optimizely={optimizely}
      user={{
        attributes: {
          app: 'field-app',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          employee_type: activeUser?.employeeType,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          employee_user_id: Number(monolithUserId),
        },
        id: `${monolithUserId}`,
      }}
    >
      <ToastCustomContainer />
      <SuperModalProvider>
        <ModalProvider>
          <TimersContext.Provider value={timersContextValue}>
            <ConnectedTimers />
            <OfflineTimersBannerContainer />
            <NavTabWrapper />
            <SegmentTracker />
            <main>
              <ImpersonationPanel />
              <FieldAppRouter initialUrl={initialUrl} />
            </main>
          </TimersContext.Provider>
        </ModalProvider>
      </SuperModalProvider>
      <Banner
        link={banner.link}
        message={banner.message}
        onClose={() => dispatch(clearBanner())}
      />
    </OptimizelyProvider>
  )
}

export default FieldAppRoot
