import React from 'react'
import { useHistory } from 'react-router-dom'

import { isBefore, startOfToday } from 'packages/utils/dateHelpers'

import { useAddTicketToVisit } from 'app/fieldapp/utils/hooks/useAddTicketToVisit/useAddTicketToVisit'

import { useTicket } from '../hooks'
import { useOpenVisitForActiveUserAndUnit } from '../hooks/useOpenVisitForActiveUserAndUnit'
import { TicketCard } from './TicketCard'

export type TicketCardContainerProps = {
  handleOpenVisitDrawer: (unitId: string) => void
  ticketId: string
}

export const TicketCardContainer: React.FC<TicketCardContainerProps> =
  React.memo(({ handleOpenVisitDrawer, ticketId }) => {
    const history = useHistory()
    const { ticket, unit } = useTicket(ticketId)
    const openVisitForUser = useOpenVisitForActiveUserAndUnit({ unit })

    const handleClick = React.useCallback(() => {
      if (ticket?.id) {
        history.push(`tickets?selectedTicket=${ticket.id}`)
      }
    }, [history, ticket?.id])

    const isOverdue = ticket?.dueDate
      ? isBefore(ticket.dueDate, startOfToday())
      : false

    const canAddToExistingVisit = !ticket?.openVisitId && !!openVisitForUser

    const { initiateAcceptAndAddTicket, loading } = useAddTicketToVisit({
      ticketId,
      visit: openVisitForUser,
    })

    if (!ticket || !unit) return null

    return (
      <TicketCard
        canAddToExistingVisit={canAddToExistingVisit}
        handleOpenVisitDrawer={handleOpenVisitDrawer}
        isLoading={loading}
        isOverdue={isOverdue}
        onAddTicketClick={initiateAcceptAndAddTicket}
        onClick={handleClick}
        ticket={ticket}
        unit={unit}
      />
    )
  })
