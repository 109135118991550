import { connect } from 'react-redux'

import { ProfilePage } from './ProfilePage'

type ReduxSelectors = 'getActiveUser'

type StateProps = ReduxSelectors
type DispatchProps = unknown
type PassThruProps = ReduxSelectors

export const ConnectedProfilePage = connect<
  StateProps,
  DispatchProps,
  PassThruProps
>(undefined)(ProfilePage)
