import styled from '@emotion/styled'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Loader } from 'packages/common'
import { useAsyncFnWithReset } from 'packages/utils/hooks'

import { ApplicationState } from 'app/fieldapp/store/store'
import { getOpenTicketsByUnitIds } from 'app/fieldapp/store/tickets/selectors/getOpenTicketsByUnitIds'
import { fetchUnitById } from 'app/fieldapp/store/units/actions'

import { DetailsTicketList } from './DetailsTicketList'

const St = {
  LoaderWrapper: styled.div`
    height: 32px;
    position: relative;
    margin-top: 32px;
  `,
}

export type DetailsTicketListContainerProps = {
  unitId: string
}

export const DetailsTicketListContainer: React.FC<DetailsTicketListContainerProps> =
  React.memo(({ unitId }) => {
    const dispatch = useDispatch()

    const [fetchUnitState, fetchUnitFn] = useAsyncFnWithReset(
      async (unitId: string) => dispatch(fetchUnitById(unitId)),
      [dispatch],
    )

    React.useEffect(() => {
      if (!unitId) return

      fetchUnitFn(unitId)
    }, [fetchUnitFn, unitId])

    const tickets = useSelector((state: ApplicationState) =>
      getOpenTicketsByUnitIds(state, [unitId]),
    )

    return fetchUnitState.loading ? (
      <St.LoaderWrapper>
        <Loader />
      </St.LoaderWrapper>
    ) : (
      <DetailsTicketList tickets={tickets} />
    )
  })
