import { noop } from 'lodash/fp'
import React from 'react'
import { useDispatch } from 'react-redux'

import { useFormFns, useToast } from 'packages/common'
import { useAsyncFnWithReset, useOnlineStatus } from 'packages/utils/hooks'

import { Slugs, useI18n } from 'app/fieldapp/i18n'
import { createTicketComment } from 'app/fieldapp/store/ticketComments/actions'
import { useActiveUser } from 'app/fieldapp/utils/hooks/useActiveUser'

import { useTicket } from '../hooks'
import type {
  TicketCommentFormProps,
  TicketCommentFormValues,
} from './TicketCommentForm'
import { TicketCommentForm } from './TicketCommentForm'

const useTranslations = () => {
  const { ut } = useI18n()
  return {
    error: ut(Slugs.addCommentError),
    success: ut(Slugs.addCommentSuccess),
  }
}

const initialFormValues: TicketCommentFormValues = {
  note: '',
}

export type TicketCommentFormContainerProps = {
  ticketId: string
}

export const TicketCommentFormContainer: React.FC<TicketCommentFormContainerProps> =
  React.memo(({ ticketId }) => {
    const dispatch = useDispatch()
    const { showToast } = useToast()
    const { isEmployee, user } = useActiveUser()
    const strings = useTranslations()

    const { ticket } = useTicket(ticketId)

    const { formValues, handleChange, resetFormValues } =
      useFormFns<TicketCommentFormValues>(initialFormValues)

    const [isExternal, setExternal] = React.useState(false)

    const [createCommentState, createCommentFn] = useAsyncFnWithReset(
      async event => {
        event.preventDefault()
        if (!user?.id) {
          return
        }

        return dispatch(
          createTicketComment(
            {
              ...formValues,
              external: isExternal,
              ticketId,
              userId: user.id,
            },
            {
              onError: () => {
                showToast({
                  message: strings.error,
                  toastType: 'danger',
                })
              },
              onSuccess: () => {
                resetFormValues()
                showToast({
                  message: strings.success,
                  toastType: 'success',
                })
              },
            },
          ),
        )
      },
      [
        dispatch,
        formValues,
        isExternal,
        resetFormValues,
        showToast,
        strings.error,
        strings.success,
        ticketId,
        user?.id,
      ],
    )

    const isOnline = useOnlineStatus().isOnline()

    const formState: TicketCommentFormProps['formState'] = React.useMemo(
      () => ({
        canSubmit: !!user?.id && !!formValues.note && isOnline,
        isExternal,
        values: formValues,
      }),
      [formValues, isExternal, isOnline, user?.id],
    )

    const handlers: TicketCommentFormProps['handlers'] = React.useMemo(
      () => ({
        cancel: noop,
        change: handleChange,
        changeToggle: setExternal,
        submit: createCommentFn,
      }),
      [createCommentFn, handleChange, setExternal],
    )

    const isVisibilityOwner = ticket?.visibility === 'owner'
    const displayVisibilityToggle = isVisibilityOwner && isEmployee

    return (
      <TicketCommentForm
        displayVisibilityToggle={displayVisibilityToggle}
        formState={formState}
        handlers={handlers}
        requestState={createCommentState}
      />
    )
  })
