import { createSelector } from 'reselect'

import { ApplicationState } from 'app/fieldapp/store/store'

import { StandardAvailabilityDrawerState } from '../ui.types'

export const getStandardAvailabilityDrawerState = createSelector(
  (state: ApplicationState) => state.ui.drawers.standardAvailabilityDrawer,
  (standardAvailabilityDrawerState: StandardAvailabilityDrawerState) =>
    standardAvailabilityDrawerState,
)
