import { isEqual, omit } from 'lodash'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useAsyncFormFns, useToast } from 'packages/common'
import { useDatePickerModal } from 'packages/common/src/DatePicker/useDatePickerModal'
import { DayOfWeekBoolMap } from 'packages/grimoire/src/utils'
import {
  addDays,
  createDateObject,
  DateFormat,
  format,
  startOfToday,
} from 'packages/utils/dateHelpers'
import { AsyncState, useAsyncFnWithReset } from 'packages/utils/hooks'

import { Slugs, useI18n } from 'app/fieldapp/i18n'
import {
  createStandardAvailability,
  deleteStandardAvailabilityById,
  fetchStandardAvailability,
  updateStandardAvailabilityById,
} from 'app/fieldapp/store/standardAvailability/actions'
import { getStandardAvailability } from 'app/fieldapp/store/standardAvailability/selectors'
import { useActiveUser } from 'app/fieldapp/utils/hooks/useActiveUser'

import { StandardAvailabilityForm } from './StandardAvailabilityForm'
import { useSubmitAvailabilityChanges } from './StandardAvailabilityForm.helpers'

const useTranslations = () => {
  const { t } = useI18n()

  return {
    createAvailabilityError: t(Slugs.createAvailabilityError),
    createAvailabilitySuccess: t(Slugs.createAvailabilitySuccess),
    deleteAvailabilityError: t(Slugs.deleteAvailabilityError),
    deleteAvailabilitySuccess: t(Slugs.deleteAvailabilitySuccess),
    updateAvailabilityError: t(Slugs.updateAvailabilityError),
    updateAvailabilitySuccess: t(Slugs.updateAvailabilitySuccess),
  }
}

const TOMORROW_DATE = addDays(startOfToday(), 1)

const TOMORROW_DATE_STRING = format(TOMORROW_DATE, DateFormat.ApiUtcWithSeconds)

export type StandardAvailabilityFormValues = {
  startDate?: string
} & DayOfWeekBoolMap

const scheduleNoWeekends: StandardAvailabilityFormValues = {
  friday: true,
  monday: true,
  saturday: false,
  sunday: false,
  thursday: true,
  tuesday: true,
  wednesday: true,
}

const defaultCurrentFormValues: StandardAvailabilityFormValues =
  scheduleNoWeekends

const defaultUpcomingFormValues: StandardAvailabilityFormValues = {
  ...scheduleNoWeekends,
  startDate: TOMORROW_DATE_STRING,
}

export type StandardAvailabilityFormContainerProps = {
  closeDrawer: () => void
  initialFetchRequestState: AsyncState<unknown>
}

export const StandardAvailabilityFormContainer: React.FC<StandardAvailabilityFormContainerProps> =
  React.memo(({ closeDrawer, initialFetchRequestState }) => {
    const strings = useTranslations()
    const dispatch = useDispatch()
    const { showToast } = useToast()

    const currentUser = useActiveUser()
    const userId = currentUser.user?.id

    const [currentAvailability, upcomingAvailability] = useSelector(
      getStandardAvailability,
    )

    const [isUpcomingSwitch, setIsUpcomingSwitch] = React.useState(
      !!upcomingAvailability,
    )

    const shouldDeleteUpcoming = upcomingAvailability && !isUpcomingSwitch

    // If we get availability values from the API use those as the
    // default form state, otherwise use the local default state
    const currentAvailabilityOrDefault = !!currentAvailability
      ? omit(currentAvailability, ['id', 'startDate'])
      : defaultCurrentFormValues

    const upcomingAvailabilityOrDefault = !!upcomingAvailability
      ? omit(upcomingAvailability, ['id'])
      : defaultUpcomingFormValues

    const currentFormManager = useAsyncFormFns<StandardAvailabilityFormValues>(
      currentAvailabilityOrDefault,
      initialFetchRequestState.loading,
    )

    const upcomingFormManager = useAsyncFormFns<StandardAvailabilityFormValues>(
      upcomingAvailabilityOrDefault,
      initialFetchRequestState.loading,
    )

    // We don't need some keys for our comparison
    const hasCurrentChanged = !isEqual(
      omit(currentAvailabilityOrDefault, ['id', 'startDate']),
      currentFormManager?.formValues,
    )
    const hasUpcomingChanged = !isEqual(
      omit(upcomingAvailabilityOrDefault, ['id']),
      upcomingFormManager?.formValues,
    )

    const createActionHandlers = React.useCallback(
      ({
        successMessage,
        errorMessage,
      }: {
        errorMessage: string
        successMessage: string
      }) => ({
        onError: () => {
          showToast({
            message: errorMessage,
            toastType: 'danger',
          })
        },
        onSuccess: () => {
          showToast({
            message: successMessage,
            toastType: 'success',
          })

          closeDrawer()
        },
      }),
      [showToast, closeDrawer],
    )

    // POST
    const [createAvailabilityState, createAvailabilityFn] = useAsyncFnWithReset(
      async (formValues: StandardAvailabilityFormValues, userId: string) => {
        if (!userId) return

        return dispatch(
          createStandardAvailability({
            callbacks: createActionHandlers({
              errorMessage: strings.createAvailabilityError,
              successMessage: strings.createAvailabilitySuccess,
            }),
            postData: formValues,
            userId,
          }),
        )
      },
      [
        createActionHandlers,
        dispatch,
        strings.createAvailabilityError,
        strings.createAvailabilitySuccess,
      ],
    )

    // PATCH
    const [updateAvailabilityState, updateAvailabilityFn] = useAsyncFnWithReset(
      async (
        formValues: StandardAvailabilityFormValues,
        availabilityId: string,
      ) => {
        if (!availabilityId) return

        return dispatch(
          updateStandardAvailabilityById({
            callbacks: createActionHandlers({
              errorMessage: strings.updateAvailabilityError,
              successMessage: strings.updateAvailabilitySuccess,
            }),
            patchData: {
              ...formValues,
              standardAvailabiltiyId: availabilityId,
            },
          }),
        )
      },
      [
        createActionHandlers,
        dispatch,
        strings.updateAvailabilityError,
        strings.updateAvailabilitySuccess,
      ],
    )

    // DELETE
    const [deleteAvailabilityState, deleteAvailabilityFn] = useAsyncFnWithReset(
      async availabilityId => {
        return dispatch(
          deleteStandardAvailabilityById({
            callbacks: createActionHandlers({
              errorMessage: strings.deleteAvailabilityError,
              successMessage: strings.deleteAvailabilitySuccess,
            }),
            standardAvailabilityId: availabilityId,
          }),
        )
      },
      [
        createActionHandlers,
        dispatch,
        strings.deleteAvailabilityError,
        strings.deleteAvailabilitySuccess,
      ],
    )

    // FETCH
    const [fetchStandardAvailabilityState, fetchStandardAvailabilityFn] =
      useAsyncFnWithReset(async () => {
        return dispatch(fetchStandardAvailability(userId))
      }, [dispatch, userId])

    const onSubmit = useSubmitAvailabilityChanges({
      createAvailabilityFn,
      currentAvailability,
      currentFormManager,
      fetchStandardAvailabilityFn,
      hasCurrentChanged,
      hasUpcomingChanged,
      isUpcomingSwitch,
      upcomingAvailability,
      upcomingFormManager,
      updateAvailabilityFn,
      userId,
    })

    const handleSubmit: React.FormEventHandler = React.useCallback(
      event => {
        event.preventDefault()
        onSubmit({
          deleteAvailabilityFn,
          fetchStandardAvailabilityFn,
          shouldDeleteUpcoming,
          upcomingAvailabilityId: upcomingAvailability?.id,
        })
      },
      [
        deleteAvailabilityFn,
        fetchStandardAvailabilityFn,
        onSubmit,
        shouldDeleteUpcoming,
        upcomingAvailability,
      ],
    )

    const handleSwitchChange = React.useCallback(
      (val: boolean) => {
        setIsUpcomingSwitch(val)
        if (!val) {
          upcomingFormManager?.resetFormValues()
        }
      },
      [upcomingFormManager],
    )

    const formState = React.useMemo(
      () => ({
        currentValues: currentFormManager?.formValues ?? null,
        isUpcomingSwitch,
        upcomingValues: upcomingFormManager?.formValues ?? null,
      }),
      [currentFormManager, isUpcomingSwitch, upcomingFormManager],
    )

    const handlers = React.useMemo(
      () => ({
        onCurrentSelectChange: currentFormManager?.setValueByKey,
        onSwitchChange: handleSwitchChange,
        onUpcomingSelectChange: upcomingFormManager?.setValueByKey,
        submit: handleSubmit,
      }),
      [
        currentFormManager,
        handleSubmit,
        handleSwitchChange,
        upcomingFormManager,
      ],
    )

    const { showDatePickerModal } = useDatePickerModal({
      disableBeforeDate: TOMORROW_DATE,
      onDateChange: selected => {
        if (!selected) return
        const formattedDate = format(selected, DateFormat.ApiUtcWithSeconds)
        upcomingFormManager?.setValueByKey('startDate', formattedDate)
      },
      selectedDate: createDateObject(upcomingFormManager?.formValues.startDate),
    })

    const isLoading =
      createAvailabilityState.loading ||
      deleteAvailabilityState.loading ||
      fetchStandardAvailabilityState.loading ||
      initialFetchRequestState.loading ||
      updateAvailabilityState.loading

    const getCanSubmit = () => {
      const isLoadingOrHasError =
        isLoading ||
        fetchStandardAvailabilityState.error ||
        initialFetchRequestState.error

      if (isLoadingOrHasError) return false

      // if the upcoming switch has been toggled, and there is no upcoming availability allow form submission
      if (isUpcomingSwitch && !upcomingAvailability) {
        return true
      }

      // If there is no current availability allow form submission
      if (!currentAvailability) {
        return true
      }

      // if the upcoming switch has not been toggled, we only need to check if the current form has changed
      if (!isUpcomingSwitch && hasCurrentChanged) {
        return true
      }

      // if the upcoming switch has been toggled, we need to check if either form has changed
      if (isUpcomingSwitch && (hasCurrentChanged || hasUpcomingChanged)) {
        return true
      }

      // if there is upcoming availability and the switch has not been toggled submitting should be true
      if (shouldDeleteUpcoming) {
        return true
      }

      return false
    }

    return (
      <StandardAvailabilityForm
        closeDrawer={closeDrawer}
        formState={formState}
        handlers={handlers}
        error={initialFetchRequestState.error}
        isLoading={isLoading}
        isSubmitDisabled={!getCanSubmit()}
        shouldDeleteUpcoming={shouldDeleteUpcoming}
        showDatePickerModal={showDatePickerModal}
      />
    )
  })
