import { createAsyncAction } from 'typesafe-actions'

import { DateFormat, format } from 'packages/utils/dateHelpers'
import {
  RequestOptions,
  RequestConfig,
} from 'packages/utils/store/jsonapi.types'

import { reviewsService } from '../reviews.service'
import {
  NormalizedReviewsApiResponse,
  ReviewsActionTypes,
} from '../reviews.types'

export const fetchReviewsByUnitIdAction = createAsyncAction(
  ReviewsActionTypes.FETCH_REVIEWS_BY_UNIT_ID,
  ReviewsActionTypes.FETCH_REVIEWS_BY_UNIT_ID_SUCCESS,
  ReviewsActionTypes.FETCH_REVIEWS_BY_UNIT_ID_FAILURE,
)<
  RequestConfig<NormalizedReviewsApiResponse>,
  NormalizedReviewsApiResponse,
  Error
>()

export const getParams = (
  unitIds: string[],
  dateSince?: Date,
): RequestOptions => {
  const reviewDateSince = dateSince
    ? { review_date: { gte: format(dateSince, DateFormat.ApiUtcShort) } }
    : {}

  return {
    fields: {},
    filter: {
      ...reviewDateSince,
      unit_id: {
        in: unitIds,
      },
    },
  }
}

export const fetchReviewsByUnitId = (unitIds: string[]) =>
  fetchReviewsByUnitIdAndDateSince(unitIds)

export const fetchReviewsByUnitIdAndDateSince =
  (unitIds: string[], dateSince?: Date) => async dispatch => {
    try {
      const params = getParams(unitIds, dateSince)
      const request = reviewsService.fetchReviews.bind(null, params)
      const result = await dispatch(
        fetchReviewsByUnitIdAction.request({ request }),
      )
      dispatch(fetchReviewsByUnitIdAction.success(result))
      return result.normalized
    } catch (error) {
      dispatch(fetchReviewsByUnitIdAction.failure(error))
      throw error
    }
  }
