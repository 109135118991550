import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'

import { useAsyncFnWithReset } from 'packages/utils/hooks'
import { splitSearchQuery } from 'packages/utils/misc'

import { ApplicationState } from 'app/fieldapp/store/store'
import { setVisitDrawer } from 'app/fieldapp/store/ui/actions'
import { getVisitDrawerState } from 'app/fieldapp/store/ui/selectors'
import { validVisitTabs, VisitTabType } from 'app/fieldapp/store/ui/ui.types'
import { fetchVisitById } from 'app/fieldapp/store/visits/actions'
import { getVisitById } from 'app/fieldapp/store/visits/selectors'

import { VisitDetailDrawer } from './VisitDetailDrawer'

const isVisitTabType = (tab: string): tab is VisitTabType => {
  return validVisitTabs.includes(tab)
}

export const VisitDetailDrawerContainer: React.FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()

  // read drawer params from URL for first load
  const { selectedVisit: selectedVisitParam = '', tab: tabParam = 'unitInfo' } =
    splitSearchQuery(location.search)

  const { isOpen, tab, visitId } = useSelector(getVisitDrawerState)

  const [fetchVisitState, fetchVisitFn] = useAsyncFnWithReset(
    async () => (visitId ? dispatch(fetchVisitById(visitId)) : null),
    [dispatch, visitId],
  )

  const visit = useSelector((state: ApplicationState) =>
    getVisitById(state, visitId),
  )

  const afterExit = React.useCallback(() => {
    history.push('/cleans')
    dispatch(
      setVisitDrawer({
        isOpen: false,
        tab: '',
        visitId: '',
      }),
    )
  }, [dispatch, history])

  // on first load, if we have the URL params to reload the drawer, attempt to do so
  React.useEffect(() => {
    if (selectedVisitParam && !isOpen) {
      const nextTab = isVisitTabType(tabParam) ? tabParam : 'unitInfo'
      dispatch(
        setVisitDrawer({
          isOpen: true,
          tab: nextTab,
          visitId: selectedVisitParam,
        }),
      )
    }
  }, [dispatch, isOpen, selectedVisitParam, tabParam])

  // if we have a visit ID but no visit is found, we are here by mistake! go back to main cleans page
  React.useEffect(() => {
    if (visitId && !visit) {
      afterExit()
    }
  }, [afterExit, visitId, visit, history])

  // when the drawer opens (based on Redux state) and we have a valid visit, update the URL to match
  React.useEffect(() => {
    const inspectionTabUrl = `/cleans?selectedVisit=${visitId}&tab=${tab}`
    const urlMatch =
      inspectionTabUrl ===
      `${window.location.pathname}${window.location.search}`
    if (isOpen && visitId && !urlMatch) {
      history.push(inspectionTabUrl)
    }
  }, [history, isOpen, tab, visitId])

  return visit ? (
    <VisitDetailDrawer
      afterExit={afterExit}
      afterOpen={fetchVisitFn}
      isLoadingVisit={fetchVisitState.loading}
      isOpen={isOpen}
    />
  ) : null
}
