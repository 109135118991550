import get from 'lodash/get' // eslint-disable-line
import { createSelector } from 'reselect'

import { format } from 'packages/utils/dateHelpers'

import { ApplicationState } from '../../store'
import { Clean } from '../cleans.types'
import { getCleans } from './getCleans'

const sortByDueDate = (a: Clean, b: Clean) => {
  if (a.dueDate === b.dueDate) return 0
  return a.dueDate > b.dueDate ? 1 : -1
}

const bucketByEffectiveDate = (acc, clean: Clean) => {
  const bucketKey = format(clean.effectiveDate, 'yyyy-MM-dd')
  acc[bucketKey] = (acc[bucketKey] || []).concat(clean)
  return acc
}

const bucketByMonth = (cleanDateBuckets: { [key: string]: Clean[] }) => {
  const bucket = {}

  Object.keys(cleanDateBuckets).forEach(dateBucketKey => {
    const cleans = cleanDateBuckets[dateBucketKey]
    const firstClean = cleans[0]

    if (get(firstClean, 'effectiveDate')) {
      const monthKey = cleans[0].effectiveDate.slice(0, 7)
      if (!(monthKey in bucket)) {
        bucket[monthKey] = {}
      }

      bucket[monthKey][dateBucketKey] = cleans
    }
  })

  return bucket
}

const getFullState = (state: ApplicationState): ApplicationState => state

/**
 * Returns all current cleans bucketed by month, then by effectiveDate.
 *
 * Only dates that have cleans assigned to them will have entries in their
 * respective month buckets--dates with no cleans are simply omitted.
 *
 * Month and date names are left in 'MMMM-YY' and 'MMMM-YY-dd' format respectively
 * in order to keep them agnostic to the UI displaying, so you may need to parse them
 * when rendering them.
 *
 * The final structure will look something like:
 * {
 *   '2019-04': {
 *     '2019-04-29': [(One or more Cleans here)],
 *     '2019-04-30': [(One or more Cleans here)],
 *   },
 *   '2019-05': {
 *     '2019-05-02': [(One or more Cleans here)],
 *     '2019-05-04': [(One or more Cleans here)],
 *   }
 * }
 *
 * Note that, while these are inserted in order and keyed by absolute dates,
 * due to the way Objects in JS work, it is not necessarily a safe assumption that
 * these will be directly iterable in chronological order, so e.g. you may want to access
 * them by key, rather than iterating directly over Object.values().
 */
export const getCleansBucketedByDate = createSelector(getFullState, state => {
  return bucketByMonth(
    getCleans(state).sort(sortByDueDate).reduce(bucketByEffectiveDate, {}),
  )
})
