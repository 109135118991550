import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Options } from 'react-select'

import { RawUser, User, fullName } from 'packages/grimoire'
import { logError } from 'packages/wiretap/logging'

import { getCrossCoverageById } from 'app/fieldapp/store/crossCoverage/selectors/getCrossCoverageById'
import { ApplicationState } from 'app/fieldapp/store/store'
import { fetchCoveragePartnerOptions } from 'app/fieldapp/store/users/actions'
import {
  getCurrentCoveragePartner,
  getUserById,
} from 'app/fieldapp/store/users/selectors'
import { hydrateRawUser } from 'app/fieldapp/store/users/users.utils'

import { useCrossCoverageDrawerTranslations } from '../../../useCrossCoverageDrawerTranslations'
import {
  CrossCoverageFormAction,
  CrossCoverageFormState,
} from '../../state/CrossCoverageForm.reducer'
import { AssigneeSelectionForm } from './AssigneeSelectionForm'

type AssigneeSelectionFormContainerProps = {
  formDispatch: React.Dispatch<CrossCoverageFormAction>
  formState: CrossCoverageFormState
}

export const AssigneeSelectionFormContainer: React.FC<AssigneeSelectionFormContainerProps> =
  React.memo(({ formDispatch, formState }) => {
    const {
      crossCoverageId,
      isAssigneeBeingOverridden,
      selectedAssignee,
      systemAssignedUserId,
    } = formState
    const strings = useCrossCoverageDrawerTranslations()

    const dispatch = useDispatch()

    const currentCoveragePartner = useSelector(getCurrentCoveragePartner)

    const crossCoverage = useSelector((applicationState: ApplicationState) =>
      crossCoverageId
        ? getCrossCoverageById(applicationState, crossCoverageId)
        : undefined,
    )

    const systemAssignedUser = useSelector((state: ApplicationState) =>
      systemAssignedUserId
        ? getUserById(state, systemAssignedUserId)
        : undefined,
    )

    const handleAssigneeChange = (user: User) => {
      formDispatch({
        payload: user,
        type: 'setSelectedAssignee',
      })
    }

    const handleAssigneeOverrideClick = () => {
      formDispatch({
        payload: true,
        type: 'setIsAssigneeBeingOverridden',
      })
    }

    const overrideSelectButtonTextValue = !!currentCoveragePartner
      ? fullName(currentCoveragePartner)
      : strings.noCoveragePartnerSelected

    /** Something of a hack so that we can still use the select to show "TBD" */
    const tbd: User = React.useMemo(
      () =>
        ({
          firstName: 'TBD',
          id: 'tbd',
          lastName: '',
        } as unknown as User),
      [],
    )

    const systemAssigneeOptions: User[] = [systemAssignedUser, tbd].filter(
      Boolean,
    ) as User[]

    const [computedSystemAssignedUser, setComputedSystemAssignedUser] =
      React.useState<User | undefined>(systemAssignedUser)

    React.useEffect(() => {
      if (crossCoverage?.userCoveringId === selectedAssignee?.id) {
        setComputedSystemAssignedUser(systemAssignedUser)
      } else {
        setComputedSystemAssignedUser(tbd)
      }
    }, [crossCoverage, selectedAssignee, tbd, systemAssignedUser])

    const debounceTimer = React.useRef<NodeJS.Timeout | null>(null)

    const loadOptions = (
      inputValue: string,
      callback: (options: Options<User>) => void,
    ): void => {
      // Clear any existing timer
      if (debounceTimer.current) {
        clearTimeout(debounceTimer.current)
      }

      // Set a new timer
      debounceTimer.current = setTimeout(async () => {
        if (inputValue.length >= 3) {
          try {
            const normalizedResult = await dispatch(
              fetchCoveragePartnerOptions(inputValue),
            )
            const usersArray: RawUser[] = Object.values(normalizedResult.user)
            const users = usersArray.map(hydrateRawUser)
            callback(users)
          } catch (error) {
            logError(error)
            callback([])
          }
        } else {
          callback([])
        }
      }, 350)
    }

    return (
      <AssigneeSelectionForm
        isAssigneeBeingOverridden={isAssigneeBeingOverridden}
        isEditing={!!crossCoverageId}
        isDisabled={!formState.isEditable}
        onAssigneeChange={handleAssigneeChange}
        overrideSelectButtonTextValue={overrideSelectButtonTextValue}
        onAssigneeOverrideClick={handleAssigneeOverrideClick}
        selectedAssignee={selectedAssignee}
        systemAssignedUser={computedSystemAssignedUser}
        systemAssigneeOptions={systemAssigneeOptions}
        loadOptions={loadOptions}
      />
    )
  })
