import { noop } from 'lodash/fp'
import React from 'react'
import { useDispatch } from 'react-redux'

import { useFormFns, useToast } from 'packages/common'
import { useAsyncFnWithReset, useOnlineStatus } from 'packages/utils/hooks'

import { Slugs, useI18n } from 'app/fieldapp/i18n'
import { saveInspectionFlagNote } from 'app/fieldapp/store/visits/actions/saveInspectionFlagNote'

import type {
  InspectionNoteFormProps,
  InspectionNoteFormValues,
} from './InspectionFlagNoteForm'
import { InspectionNoteForm } from './InspectionFlagNoteForm'

const useTranslations = () => {
  const { ut } = useI18n()
  return {
    error: ut(Slugs.addNoteError),
    success: ut(Slugs.addNoteSuccess),
  }
}

export type InspectionFlagNoteFormContainerProps = {
  flagId: string
  inspectionFlagNotes: string | null
  visitId: string
}

export const InspectionFlagNoteFormContainer: React.FC<InspectionFlagNoteFormContainerProps> =
  React.memo(({ flagId, inspectionFlagNotes, visitId }) => {
    const dispatch = useDispatch()
    const { showToast } = useToast()
    const strings = useTranslations()
    const initialFormValues: InspectionNoteFormValues = {
      inspectionFlagNotes: inspectionFlagNotes || '',
    }

    const { formValues, handleChange } =
      useFormFns<InspectionNoteFormValues>(initialFormValues)

    const [addNoteState, addNoteFn] = useAsyncFnWithReset(
      async event => {
        event.preventDefault()

        return dispatch(
          saveInspectionFlagNote({
            callbacks: {
              onError: () => {
                showToast({
                  message: strings.error,
                  toastType: 'danger',
                })
              },
              onSuccess: () => {
                showToast({
                  message: strings.success,
                  toastType: 'success',
                })
              },
            },
            saveData: {
              inspectionFlagId: flagId,
              note: formValues.inspectionFlagNotes,
              visitId: visitId,
            },
          }),
        )
      },
      [
        dispatch,
        flagId,
        formValues,
        showToast,
        strings.error,
        strings.success,
        visitId,
      ],
    )

    const isOnline = useOnlineStatus().isOnline()

    const formState: InspectionNoteFormProps['formState'] = React.useMemo(
      () => ({
        canSubmit: isOnline && !addNoteState.loading,
        values: formValues,
      }),
      [addNoteState.loading, formValues, isOnline],
    )

    const handlers: InspectionNoteFormProps['handlers'] = React.useMemo(
      () => ({
        cancel: noop,
        change: handleChange,
        submit: addNoteFn,
      }),
      [addNoteFn, handleChange],
    )

    return (
      <InspectionNoteForm
        flagId={flagId}
        formState={formState}
        handlers={handlers}
        requestState={addNoteState}
      />
    )
  })
