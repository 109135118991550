import * as React from 'react'
import { Route, Switch } from 'react-router-dom'

import { ConnectedTicketsPage } from './TicketsPage'

export const TicketsIndex: React.FC = props => {
  return (
    <Switch>
      <Route
        path="/tickets"
        render={routerProps => (
          <ConnectedTicketsPage {...props} {...routerProps} />
        )}
      />
    </Switch>
  )
}
