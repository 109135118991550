import { createSelector } from 'reselect'

import { ApplicationState } from '../../store'
import { getUserByHkId } from '../../users/selectors'
import { Housekeeper } from '../housekeepers.types'
import { getHkById } from './getHkById'

/**
 * Returns all housekeepers (cached via reselect) should recompute if state changes
 *
 * @param state
 */
export const getHousekeeperManagersForZone = createSelector(
  (state: ApplicationState): string[] => Object.keys(state.housekeepers.data),
  (state: ApplicationState) => state,
  (_, zoneId: string) => zoneId,
  (housekeeperIds, state, zoneId): Housekeeper[] => {
    const managers = housekeeperIds
      .map(id => getHkById(state, id))
      .filter(hk => {
        return (
          hk.zoneId === zoneId &&
          getUserByHkId(state, hk.id)?.isHousekeepingManager
        )
      }) as Housekeeper[]
    return managers
  },
)
