import { createSelector } from 'reselect'

import { ApplicationState } from 'app/fieldapp/store/store'

import { CrossCoverageDrawerState } from '../ui.types'

export const getCrossCoverageDrawerState = createSelector(
  (state: ApplicationState) => state.ui.drawers.CrossCoverageDrawer,
  (CrossCoverageDrawerState: CrossCoverageDrawerState) =>
    CrossCoverageDrawerState,
)
