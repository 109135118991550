import styled from '@emotion/styled'
import { useDecision } from '@optimizely/react-sdk'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getTaskProgressStatus } from 'packages/grimoire'
import { Features } from 'packages/optimizely/src/features'
import { colors } from 'packages/styles'

import { Slugs, useI18n } from 'app/fieldapp/i18n'
import { useAppSelector } from 'app/fieldapp/store/hooks'
import { ApplicationState } from 'app/fieldapp/store/store'
import { setVisitDrawer } from 'app/fieldapp/store/ui/actions'
import { getVisitDrawerState } from 'app/fieldapp/store/ui/selectors'
import { VisitTabType, validVisitTabs } from 'app/fieldapp/store/ui/ui.types'
import { getVisitById } from 'app/fieldapp/store/visits/selectors'

import { TaskDetailWrapperStyles } from '../../TaskSharedStyles'
import { VisitInspectionChecklistContainer } from '../../VisitInspectionChecklist'
import {
  VisitReviewsTabContainer,
  VisitTicketsTabContainer,
} from '../components'
import { VisitDetail } from '../VisitDetail'
import { VisitDetailDrawerId } from '../VisitDetailDrawer/VisitDetailDrawer.utils'

const scrollToDrawerTop = (): void => {
  const drawerRoot = document.querySelector(`#${VisitDetailDrawerId}`)
  if (drawerRoot?.scrollTo) {
    drawerRoot.scrollTo(0, 0)
  }
}

export const St = {
  ...TaskDetailWrapperStyles,
  InProgressDot: styled.span`
    background-color: ${colors.alert};
    border-radius: 50%;
    display: inline-flex;
    height: 10px;
    margin-left: 8px;
    width: 10px;
  `,
  Invalid: styled.div`
    padding: 24px;
  `,
}

const useTranslations = () => {
  const { t, ut } = useI18n()

  return {
    inspection: t(Slugs.inspection),
    reviews: t(Slugs.reviews),
    tickets: ut(Slugs.tickets),
    unitInfo: t(Slugs.unitInfo),
  }
}

export enum VisitDetailWrapperTestIds {
  InProgress = 'VisitDetailWrapper__InProgress',
}

export type VisitDetailWrapperProps = {
  isLoadingVisit: boolean
  onCloseDrawerClick: () => void
}

export const VisitDetailWrapper: React.FC<VisitDetailWrapperProps> = React.memo(
  ({ isLoadingVisit, onCloseDrawerClick }) => {
    const [decision] = useDecision(Features.GUEST_REVIEWS)
    const isReviewsEnabled = decision.enabled

    const { visitId } = useAppSelector(getVisitDrawerState)

    const visit = useAppSelector((state: ApplicationState) =>
      getVisitById(state, visitId),
    )
    const strings = useTranslations()

    const dispatch = useDispatch()
    const { tab = 'unitInfo' } = useSelector(getVisitDrawerState)

    const visitIsInProgress =
      !!visit && getTaskProgressStatus(visit) === 'inProgress'

    const selectTab = React.useCallback(
      (newTab: VisitTabType) => () => {
        if (visit?.id) {
          dispatch(setVisitDrawer({ tab: newTab }))
          scrollToDrawerTop()
        }
      },
      [dispatch, visit?.id],
    )

    // whenever 'tab' changes, validate it; if it is invalid, re-route to a safe tab
    React.useEffect(() => {
      if (visit?.id && !validVisitTabs.includes(tab)) {
        dispatch(setVisitDrawer({ tab: 'unitInfo' }))
      }
    }, [dispatch, tab, visit?.id])

    return (
      <>
        <St.Header>
          <St.HeaderControls>
            <St.CloseButton onClick={onCloseDrawerClick} />
          </St.HeaderControls>

          <St.TabWrapper>
            <St.Tab active={tab === 'unitInfo'} onClick={selectTab('unitInfo')}>
              {strings.unitInfo}
              {visitIsInProgress && (
                <St.InProgressDot
                  data-testid={VisitDetailWrapperTestIds.InProgress}
                />
              )}
            </St.Tab>

            <St.Tab active={tab === 'tickets'} onClick={selectTab('tickets')}>
              {strings.tickets}
            </St.Tab>

            {isReviewsEnabled && (
              <St.Tab active={tab === 'reviews'} onClick={selectTab('reviews')}>
                {strings.reviews}
              </St.Tab>
            )}

            <St.Tab
              active={tab === 'inspection'}
              onClick={selectTab('inspection')}
            >
              {strings.inspection}
            </St.Tab>
          </St.TabWrapper>
        </St.Header>

        {!!visit && tab === 'unitInfo' && (
          <VisitDetail onCloseDrawerClick={onCloseDrawerClick} />
        )}

        {!!visit && tab === 'tickets' && (
          <VisitTicketsTabContainer visitId={visit.id} />
        )}

        {isReviewsEnabled && !!visit && tab === 'reviews' && (
          <VisitReviewsTabContainer visitId={visit.id} />
        )}

        {!!visit && tab === 'inspection' && (
          <VisitInspectionChecklistContainer isLoadingVisit={isLoadingVisit} />
        )}

        {!visit && <St.Invalid>Invalid Visit Details</St.Invalid>}
      </>
    )
  },
)
