import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useCompleteModal, useToast } from 'packages/common'
import { TicketStatus } from 'packages/grimoire/src/ticket'
import { useAsyncFnWithReset } from 'packages/utils/hooks'

import { useTicket } from 'app/fieldapp/components/tickets/hooks'
import { Slugs, useI18n } from 'app/fieldapp/i18n'
import { ApplicationState } from 'app/fieldapp/store/store'
import {
  fetchTicketById,
  updateTicket,
} from 'app/fieldapp/store/tickets/actions'
import { setVisitTicketDrawer } from 'app/fieldapp/store/ui/actions'
import {
  getVisitDrawerState,
  getVisitTicketDrawerState,
} from 'app/fieldapp/store/ui/selectors'
import { getVisitById } from 'app/fieldapp/store/visits/selectors'
import { useActiveUser } from 'app/fieldapp/utils/hooks/useActiveUser'

import { useTicketDetails } from '../../../tickets/TicketDetail/useTicketDetails'
import { VisitTicketDrawer } from './VisitTicketDrawer'
import {
  VisitTicketDrawerContext,
  VisitTicketDrawerContextType,
} from './VisitTicketDrawer.context'

const useTranslations = () => {
  const { ut } = useI18n()

  return {
    completeTicketError: ut(Slugs.ticketCompleteError),
    completeTicketSuccess: ut(Slugs.ticketCompleteSuccess),
    completeTicketText: ut(Slugs.completeTicketModalText),
    completeTicketTitle: ut(Slugs.completeTicketModalTitle),
  }
}

export const VisitTicketDrawerContainer: React.FC = () => {
  const dispatch = useDispatch()
  const strings = useTranslations()
  const { showToast } = useToast()

  const { user } = useActiveUser()
  const { visitId } = useSelector(getVisitDrawerState)
  const visit = useSelector((state: ApplicationState) =>
    getVisitById(state, visitId),
  )

  const { isOpen, ticketId } = useSelector(getVisitTicketDrawerState)
  const ticketDetails = useTicket(ticketId)
  const ticketTimerProps = useTicketDetails(ticketDetails?.ticket)

  const afterExit = React.useCallback(() => {
    dispatch(
      setVisitTicketDrawer({
        isOpen: false,
        ticketId: '',
      }),
    )
  }, [dispatch])

  const [fetchTicketState, fetchTicketFn] = useAsyncFnWithReset(
    async (ticketId: string) => dispatch(fetchTicketById(ticketId)),
    [dispatch],
  )

  const [completeTicketState, completeTicketFn] = useAsyncFnWithReset(
    async () =>
      dispatch(
        updateTicket(
          {
            id: ticketId,
            status: TicketStatus.RESOLVED_COMPLETED,
          },
          {
            onError: () => {
              showToast({
                message: strings.completeTicketError,
                toastType: 'danger',
              })
            },
            onSuccess: () => {
              showToast({
                message: strings.completeTicketSuccess,
                toastType: 'success',
              })
            },
          },
        ),
      ),
    [
      dispatch,
      ticketId,
      showToast,
      strings.completeTicketError,
      strings.completeTicketSuccess,
    ],
  )

  const { showModal: showCompleteTicketModal } = useCompleteModal({
    onConfirm: completeTicketFn,
    slugs: {
      message: strings.completeTicketText,
      title: strings.completeTicketTitle,
    },
  })

  const contextValue: VisitTicketDrawerContextType = React.useMemo(
    () => ({
      fetchTicketState,
    }),
    [fetchTicketState],
  )

  React.useEffect(() => {
    if (isOpen && ticketId) {
      fetchTicketFn(ticketId)
    }
  }, [fetchTicketFn, isOpen, ticketId])

  // if we have a ticket ID but no ticket is found, we are here by mistake; close the drawer
  React.useEffect(() => {
    if (ticketId && !ticketDetails.ticket) {
      afterExit()
    }
  }, [afterExit, ticketDetails.ticket, ticketId])

  return (
    <VisitTicketDrawerContext.Provider value={contextValue}>
      <VisitTicketDrawer
        afterExit={afterExit}
        completeTicketRequestState={completeTicketState}
        isOpen={isOpen}
        onCompleteTicketClick={showCompleteTicketModal}
        ticketDetails={ticketDetails}
        ticketTimerProps={ticketTimerProps}
        user={user}
        visit={visit}
      />
    </VisitTicketDrawerContext.Provider>
  )
}
