import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { AsyncState, useAsyncFnWithReset } from 'packages/utils/hooks'

import { ApplicationState } from 'app/fieldapp/store/store'
import { Ticket } from 'app/fieldapp/store/tickets'
import { getTicketsByIds } from 'app/fieldapp/store/tickets/selectors'
import { fetchUnitById } from 'app/fieldapp/store/units/actions'
import { getUnitById } from 'app/fieldapp/store/units/selectors'

type UseFetchUnit = {
  fetchUnitState: AsyncState<unknown>
  tickets: Ticket[]
  unit: ReturnType<typeof getUnitById>
}

export const useFetchUnit = (unitId: string): UseFetchUnit => {
  const dispatch = useDispatch()
  const unit = useSelector((state: ApplicationState) =>
    getUnitById(state, unitId),
  )

  const tickets = useSelector((state: ApplicationState) =>
    getTicketsByIds(state, unit?.openTicketIds || []),
  )

  const [fetchUnitState, fetchUnitFn] = useAsyncFnWithReset(
    async (id: string) => dispatch(fetchUnitById(id)),
    [dispatch],
  )

  React.useEffect(() => {
    if (!unitId) return

    fetchUnitFn(unitId)
  }, [fetchUnitFn, unitId])

  return { fetchUnitState, tickets, unit }
}
