import styled from '@emotion/styled'
import { noop } from 'lodash/fp'
import React from 'react'
import { useDispatch } from 'react-redux'

import { useFormFns, useToast } from 'packages/common'
import { centerWithFlex, colors, text } from 'packages/styles'
import { useAsyncFnWithReset } from 'packages/utils/hooks'

import { Slugs, useI18n } from 'app/fieldapp/i18n'
import { Unit } from 'app/fieldapp/store/units'
import { createVisit } from 'app/fieldapp/store/visits/actions'
import { useActiveUser } from 'app/fieldapp/utils/hooks/useActiveUser'

import {
  CreateVisitForm,
  CreateVisitFormProps,
  CreateVisitFormValues,
} from './CreateVisitForm'
import { useFetchUnit, useUnitVisits } from './hooks'

const St = {
  Form: styled(CreateVisitForm)`
    padding: 16px;
  `,
  Header: styled.div`
    ${text.headingCard};
    ${centerWithFlex};
    background: ${colors.midnight10};
    padding-bottom: 20px;
    padding-top: 48px;
  `,
}

const useTranslations = () => {
  const { ut } = useI18n()
  return {
    addVisit: ut(Slugs.addVisit),
    success: ut(Slugs.addVisitSuccessMessage),
  }
}

type CreateVisitContainerProps = {
  initialUnitId?: string
  onCancel?: () => void
  onSuccess?: () => void
}

export const CreateVisitContainer: React.FC<CreateVisitContainerProps> =
  React.memo(({ initialUnitId = '', onCancel = noop, onSuccess = noop }) => {
    const dispatch = useDispatch()
    const strings = useTranslations()
    const { showToast } = useToast()

    const { housekeeperId } = useActiveUser()

    const initialFormValues: CreateVisitFormValues = {
      unitId: initialUnitId,
    }

    const { formValues, setValueByKey } =
      useFormFns<CreateVisitFormValues>(initialFormValues)
    const setUnitId = setValueByKey('unitId')

    const { fetchUnitState, unit } = useFetchUnit(formValues?.unitId || '')
    const { userHasExistingVisit } = useUnitVisits(unit?.id || '')

    const [createVisitState, createVisitFn] = useAsyncFnWithReset(async () => {
      if (!formValues.unitId || !housekeeperId) return

      return dispatch(
        createVisit(
          {
            housekeeperId,
            unitId: formValues.unitId,
          },
          {
            onSuccess: () => {
              showToast({ message: strings.success })
              onSuccess()
            },
          },
        ),
      )
    }, [
      dispatch,
      formValues.unitId,
      housekeeperId,
      onSuccess,
      showToast,
      strings.success,
    ])

    const formState: CreateVisitFormProps['formState'] = React.useMemo(
      () => ({
        canSubmit: !!formValues.unitId,
        values: formValues,
      }),
      [formValues],
    )

    const handleUnitChange = React.useCallback(
      (unit: Unit) => {
        setUnitId(unit.id)
      },
      [setUnitId],
    )

    const handleSubmit: React.FormEventHandler = React.useCallback(
      event => {
        event.preventDefault()
        createVisitFn()
      },
      [createVisitFn],
    )

    const handlers: CreateVisitFormProps['handlers'] = React.useMemo(
      () => ({
        cancel: onCancel,
        change: noop,
        selectUnit: handleUnitChange,
        submit: handleSubmit,
      }),
      [handleSubmit, handleUnitChange, onCancel],
    )

    return (
      <>
        <St.Header>{strings.addVisit}</St.Header>
        <St.Form
          canCreateVisit={!userHasExistingVisit}
          fetchUnitState={fetchUnitState}
          formState={formState}
          handlers={handlers}
          requestState={createVisitState}
          unit={unit}
        />
      </>
    )
  })
