import * as React from 'react'
import { Route, Switch } from 'react-router-dom'

import { SchedulePage } from '../SchedulePage/SchedulePage'

export const ScheduleIndex: React.FC = () => {
  return (
    <Switch>
      <Route path="/cleans" render={() => <SchedulePage />} />
    </Switch>
  )
}
