import React from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { useTicket } from 'app/fieldapp/components/tickets/hooks'
import { getAuthUser } from 'app/fieldapp/store/users/selectors'

import { TicketDetailDrawer } from './TicketDetailDrawer'

export type TicketDetailDrawerContainerProps = { ticketId: string | undefined }

export const TicketDetailDrawerContainer: React.FC<
  TicketDetailDrawerContainerProps
> = ({ ticketId = '' }) => {
  const history = useHistory()

  const authUser = useSelector(getAuthUser)
  const ticketDetails = useTicket(ticketId)

  const afterExit = React.useCallback(() => {
    history.push('/tickets')
  }, [history])

  // if we have a ticket ID but no ticket is found, we are here by mistake; go back to main tickets page
  React.useEffect(() => {
    if (ticketId && !ticketDetails.ticket) {
      history.push('/tickets')
    }
  }, [history, ticketDetails.ticket, ticketId])

  return (
    <TicketDetailDrawer
      afterExit={afterExit}
      isOpen={!!ticketDetails.ticket}
      ticketDetails={ticketDetails}
      userId={authUser?.id || 'unknown'}
    />
  )
}
