import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useToast } from 'packages/common'
import { useManageConnectedDrawerState } from 'packages/common/src/modals/Drawer/hooks/useManageConnectedDrawerState'
import { createDateString } from 'packages/utils/dateHelpers'
import { useAsyncFnWithReset } from 'packages/utils/hooks'
import { JsonApiErrorResponse } from 'packages/utils/store'

import { deleteCrossCoverage } from 'app/fieldapp/store/crossCoverage/actions/deleteCrossCoverage'
import { fetchCrossCoverages } from 'app/fieldapp/store/crossCoverage/actions/fetchCrossCoverages'
import { updateCrossCoverage } from 'app/fieldapp/store/crossCoverage/actions/updateCrossCoverage'
import { setCrossCoverageDrawer } from 'app/fieldapp/store/ui/actions'
import { getCrossCoverageDrawerState } from 'app/fieldapp/store/ui/selectors'
import { getActiveUser } from 'app/fieldapp/store/users/selectors'

import { useCrossCoverageDrawerTranslations } from '../../../../useCrossCoverageDrawerTranslations'
import {
  FormValidationError,
  makeCrossCoveragePayload,
} from '../../../../utils/CrossCoverageState.helpers'
import {
  CrossCoverageFormAction,
  CrossCoverageFormState,
} from '../../../state/CrossCoverageForm.reducer'
import { CrossCoverageFormControls } from '../CrossCoverageFormControls'
import { useValidation } from './validation.utils'

export const getSubmitIsDisabled = (state: CrossCoverageFormState): boolean => {
  const isErrorFree = state.formValidationError === undefined
  const hasRepeatingValue = state.isRepeating !== null
  const hasSelectedAssignee = state.selectedAssignee !== undefined
  const hasRepeatDays = state.isRepeating === false || state.repeatDays?.length
  const isEditable = state.isEditable

  return !(
    isErrorFree &&
    hasRepeatingValue &&
    hasSelectedAssignee &&
    hasRepeatDays &&
    isEditable
  )
}

export type CrossCoverageFormControlsContainerProps = {
  formDispatch: React.Dispatch<CrossCoverageFormAction>
  formState: CrossCoverageFormState
}

export const EditCrossCoverageFormContainer: React.FC<
  CrossCoverageFormControlsContainerProps
> = ({ formDispatch, formState }) => {
  const activeUser = useSelector(getActiveUser)
  const dispatch = useDispatch()
  const strings = useCrossCoverageDrawerTranslations()

  const drawerManager = useManageConnectedDrawerState({
    dispatchableToggleDrawerAction: setCrossCoverageDrawer,
    selector: getCrossCoverageDrawerState,
  })

  const { showToast } = useToast()

  const validateFormState = useValidation({
    activeUser,
    changeType: 'update',
    formDispatch,
  })

  const [handleSubmitButtonState, handleSubmitButtonClick] =
    useAsyncFnWithReset(async () => {
      if (
        !validateFormState(formState) ||
        !activeUser?.id ||
        !formState.crossCoverageId
      ) {
        return
      }

      return dispatch(
        updateCrossCoverage({
          callbacks: {
            onError: (error: JsonApiErrorResponse) => {
              return formDispatch({
                payload: new FormValidationError(
                  error.response?.data?.errors?.[0]?.detail ||
                    strings.updateXCovFailure,
                ),
                type: 'setFormValidationError',
              })
            },
            onSuccess: () => {
              showToast({
                message: strings.updateXCovSuccess,
                toastType: 'success',
              })

              formDispatch({
                payload: null,
                type: 'changeIsRepeatingAndReset',
              })

              dispatch(
                fetchCrossCoverages({
                  startDateGte: createDateString(),
                }),
              )

              drawerManager.closeDrawer()
            },
          },
          id: formState.crossCoverageId,
          patchData: makeCrossCoveragePayload(formState, activeUser.id),
        }),
      )
    }, [
      activeUser?.id,
      dispatch,
      drawerManager,
      formDispatch,
      formState,
      showToast,
      strings.updateXCovFailure,
      strings.updateXCovSuccess,
      validateFormState,
    ])

  const [handleDeleteButtonState, handleDeleteButtonClick] =
    useAsyncFnWithReset(async () => {
      if (!formState.crossCoverageId) {
        return
      }

      return dispatch(
        deleteCrossCoverage({
          callbacks: {
            onError: (error: JsonApiErrorResponse) => {
              return formDispatch({
                payload: new FormValidationError(
                  error.response?.data?.errors?.[0]?.detail ||
                    strings.deleteXCovFailure,
                ),
                type: 'setFormValidationError',
              })
            },
            onSuccess: () => {
              showToast({
                message: strings.deleteXCovSuccess,
                toastType: 'success',
              })

              dispatch(
                fetchCrossCoverages({
                  startDateGte: createDateString(),
                }),
              )

              drawerManager.closeDrawer()
            },
          },
          id: formState.crossCoverageId,
        }),
      )
    }, [
      dispatch,
      drawerManager,
      formDispatch,
      formState.crossCoverageId,
      showToast,
      strings.deleteXCovFailure,
      strings.deleteXCovSuccess,
    ])

  return (
    <CrossCoverageFormControls
      crossCoverageId={formState.crossCoverageId}
      handleSubmitButtonClick={handleSubmitButtonClick}
      handleCancelButtonClick={handleDeleteButtonClick}
      isLoading={
        handleSubmitButtonState.loading || handleDeleteButtonState.loading
      }
      submitIsDisabled={getSubmitIsDisabled(formState)}
    />
  )
}
