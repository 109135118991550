import { combineReducers } from 'redux'

import appReducer from './app/app.slice'
import { assignmentsReducer } from './assignments/assignments.reducer'
import authReducer from './auth/auth.slice'
import { cleansReducer } from './cleans/cleans.reducer'
import { cleanTimesReducer } from './cleantimes/cleantimes.reducer'
import { commentsReducer } from './comments/comments.reducer'
import coverageReducer from './coverage/coverage.slice'
import crossCoverageReducer from './crossCoverage/crossCoverage.slice'
import { housekeepersReducer } from './housekeepers/housekeepers.reducer'
import inspectionFlagReducer from './inspectionFlags/inspectionFlag.slice'
import { lockboxesReducer } from './lockboxes/lockboxes.reducer'
import { otherTimersReducer } from './otherTimers/otherTimers.reducer'
import reservationsReducer from './reservations/reservations.slice'
import { reviewsReducer } from './reviews/reviews.reducer'
import { smartLocksReducer } from './smartlocks/smartlocks.reducer'
import standardAvailabilityReducer from './standardAvailability/standardAvailability.slice'
import { taskPhotosReducer } from './taskPhotos/taskPhotos.reducer'
import { ticketTimesReducer } from './ticket-times/ticket-times.reducer'
import { ticketCommentsReducer } from './ticketComments/ticketComments.reducer'
import { ticketsReducer } from './tickets/tickets.reducer'
import uiReducer from './ui/ui.slice'
import { unitsReducer } from './units/units.reducer'
import { usersReducer } from './users/users.reducer'
import { visitsReducer } from './visits/visits.reducer'

export const reducers = {
  app: appReducer,
  assignments: assignmentsReducer,
  auth: authReducer,
  cleans: cleansReducer,
  cleanTimes: cleanTimesReducer,
  comments: commentsReducer,
  coverage: coverageReducer,
  crossCoverage: crossCoverageReducer,
  housekeepers: housekeepersReducer,
  inspectionFlags: inspectionFlagReducer,
  lockboxes: lockboxesReducer,
  otherTimers: otherTimersReducer,
  reservations: reservationsReducer,
  reviews: reviewsReducer,
  smartLocks: smartLocksReducer,
  standardAvailability: standardAvailabilityReducer,
  taskPhotos: taskPhotosReducer,
  ticketComments: ticketCommentsReducer,
  tickets: ticketsReducer,
  ticketTimes: ticketTimesReducer,
  ui: uiReducer,
  units: unitsReducer,
  users: usersReducer,
  visits: visitsReducer,
}

export const rootReducer = combineReducers(reducers)
