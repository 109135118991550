import { createSelector } from 'reselect'

import { ApplicationState } from 'app/fieldapp/store/store'

import { VisitTicketDrawerState } from '../ui.types'

export const getVisitTicketDrawerState = createSelector(
  (state: ApplicationState) => state.ui.drawers.visitTicketDrawer,
  (unitDrawerState: VisitTicketDrawerState) => unitDrawerState,
)
