import { createSelector } from 'reselect'

import { transformNormalizedToTyped } from 'packages/utils/store/store.utils'

import { ApplicationState } from '../../store'
import { SearchUnit, Unit, UnitAttributeNames } from '../units.types'

const getRawUnitSearchResultUnitValues = (state: ApplicationState) =>
  Object.values(state.units.searchResults.unit)

const getRawUnitSearchResultZones = (state: ApplicationState) =>
  state.units.searchResults.zone

const getZoneIdFromUnit = unit => unit?.relationships?.zone?.data?.id

function attachZone(unit, searchResultsZone) {
  return {
    ...unit,
    zone: searchResultsZone?.[getZoneIdFromUnit(unit)] ?? null,
  }
}

/**
 * Strips the zone from a unit, normalizes the unit and zone, then re-appends the zone to the unit
 * These units are "SearchUnits" with a limited set of fields, and the attached Zone only includes a name and id.
 * @param unit
 */
function normalizeUnitWithZone(unit): SearchUnit {
  const { zone, ...unitWithNoZone } = unit
  const normalizedUnitWithoutZone = transformNormalizedToTyped<Unit>(
    unitWithNoZone,
    UnitAttributeNames,
  )
  const normalizedZone = zone
    ? transformNormalizedToTyped<{ id: string; name: string }>(zone, ['name'])
    : zone

  return {
    ...normalizedUnitWithoutZone,
    zone: normalizedZone,
  }
}

/**
 * Returns the current Unit search results (if any), as an array
 *
 */
export const getUnitSearchResults = createSelector(
  getRawUnitSearchResultUnitValues,
  getRawUnitSearchResultZones,
  (searchUnitsArray, searchZones) => {
    return searchUnitsArray
      .map(unit => attachZone(unit, searchZones))
      .map(normalizeUnitWithZone)
  },
)
