import styled from '@emotion/styled'
import React from 'react'

import { Slugs, useI18n } from 'packages/i18n'
import { useAsyncFnWithReset, useOnlineStatus } from 'packages/utils/hooks'

// FYI, trying to import these from 'packages/common' directly breaks Storybook for some reason
import { Alert } from '../../../../Alert'
import { Button } from '../../../../Button'
import { Switch } from '../../../../Switch'
import { ModalContent, ModalTitle } from '../../Modal.styles'
import { ModalChildrenProps } from '../../ModalPortal'

const St = {
  BodyContent: styled.div`
    font-size: 14px;
    margin-bottom: 24px;
    margin-top: 16px;
  `,
  FooterContent: styled.div`
    font-size: 14px;
    margin-bottom: 8px;
    margin-top: 16px;
  `,
  List: styled.ul`
    margin: 0;
    margin-bottom: 8px;
    padding: 0;
    padding-left: 24px;
  `,
  ModalContent,
  ModalTitle: styled(ModalTitle)`
    margin-bottom: 32px;
    margin-top: 8px;
  `,
  Switch: styled(Switch)`
    flex-shrink: 0;
  `,
  Toggle: styled.div`
    align-items: center;
    display: flex;
    font-weight: 600;
    grid-gap: 16px;

    span {
      padding-top: 1px; // just for better visual vertical alignment of toggle label
    }
  `,
}

export type ToggleModalConfig = {
  bodyContent: React.ReactNode
  buttonText: string
  footerContent: React.ReactNode
  title: string
  toggleLabel: string
}

export type ToggleModalProps = ModalChildrenProps & {
  config: {
    disabled: ToggleModalConfig
    enabled: ToggleModalConfig
  }
  onConfirm: () => Promise<unknown>
}

export const ToggleModal: React.FC<ToggleModalProps> = React.memo(
  ({ beginClose, config, onConfirm }) => {
    const { t } = useI18n()
    const isOnline = useOnlineStatus().isOnline()
    const [switchState, setSwitchState] = React.useState(false)
    const activeConfig = switchState ? config.enabled : config.disabled

    const [confirmState, handleConfirm] = useAsyncFnWithReset(
      async () => await onConfirm(),
      [onConfirm],
    )

    React.useEffect(() => {
      if (confirmState.value) beginClose()
    }, [beginClose, confirmState])

    return (
      <St.ModalContent>
        <St.ModalTitle>{activeConfig.title}</St.ModalTitle>

        <St.Toggle>
          <St.Switch
            checked={switchState}
            onToggle={() => setSwitchState(prev => !prev)}
          />
          <span>{activeConfig.toggleLabel}</span>
        </St.Toggle>

        <St.BodyContent>{activeConfig.bodyContent}</St.BodyContent>

        {confirmState.error && (
          <Alert alertType={'danger'}>{t(Slugs.oopsChangesNotSaved)}</Alert>
        )}

        <Button
          block={true}
          disabled={!isOnline}
          isLoading={confirmState.loading}
          onClick={switchState ? handleConfirm : beginClose}
          buttonType={switchState ? 'primary' : 'utility'}
        >
          {activeConfig.buttonText}
        </Button>

        <St.FooterContent>{activeConfig.footerContent}</St.FooterContent>
      </St.ModalContent>
    )
  },
)
