import { produce } from 'immer'

import { transformNormalizedToTyped } from 'packages/utils/store'

import {
  Coverage,
  CoverageAttributeNames,
  CoverageResponse,
  NormalizedCoverageApiResponse,
  RawCoverage,
} from './coverage.types'

export const emptyNormalizedCoverageData: CoverageResponse = Object.freeze({
  coverage: {},
  user: {},
})

export const emptyCoverageResponse: NormalizedCoverageApiResponse =
  Object.freeze({
    normalized: emptyNormalizedCoverageData,
    raw: { data: [] },
  })

export const hydrateRawCoverage = (raw: RawCoverage): Coverage => {
  const normalized = transformNormalizedToTyped<Coverage>(
    raw,
    CoverageAttributeNames,
  )

  return produce(normalized, draft => {
    draft.crossCoverageId = raw.relationships?.crossCoverage?.data?.id
    draft.userId = raw.relationships.user.data.id
    draft.requestedUserId = raw.relationships.requestedUser?.data?.id
  })
}
