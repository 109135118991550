import { createSelector } from 'reselect'

import { ApplicationState } from 'app/fieldapp/store/store'

import { ReviewDrawerState } from '../ui.types'

export const getReviewDrawerState = createSelector(
  (state: ApplicationState) => state.ui.drawers.reviewDrawer,
  (reviewDrawer: ReviewDrawerState) => reviewDrawer,
)
