import classNames from 'classnames'
import * as React from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { IconName, SvgIcon } from 'packages/iconic'

import styles from './NavTabWrapper.module.scss'

export const TEST_ID_NAV_TAB = 'navTab'

const iconTextStyles: React.CSSProperties = {
  marginLeft: '.5rem',
}

export type NavTabConfig = {
  icon: IconName
  route: string
  text: string
}

export type NavTabProps = {
  config: NavTabConfig
}

export const NavTab: React.FC<NavTabProps> = React.memo(({ config }) => {
  const { pathname: current } = useLocation()
  const { push: navigateTo } = useHistory()
  const { icon, text } = config

  const handleClick = React.useCallback(() => {
    navigateTo(config.route)
  }, [config.route, navigateTo])

  /**
   * Determines if this tab's route is the current route.
   * Note that this is a shallow check which only considers
   * the first section of the path, so e.g.
   * `/cleans` would be considered equal to `/cleans/{id}`
   */
  const isCurrentRoute = React.useMemo(() => {
    const pathRoot = (path: string) => path.split('/')[1]
    return pathRoot(current) === pathRoot(config.route)
  }, [current, config.route])

  return (
    <div
      className={classNames(styles.navTab, {
        [styles.current]: isCurrentRoute,
      })}
      data-testid={TEST_ID_NAV_TAB}
      onClick={handleClick}
    >
      <SvgIcon
        centerItems={true}
        icon={icon}
        size={16}
        text={text}
        textFontSize={15}
        textStyleOverrides={iconTextStyles}
      />
    </div>
  )
})
