import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useMount } from 'react-use'

import { useAsyncFnWithReset } from 'packages/utils/hooks'

import { fetchCrossCoverages } from 'app/fieldapp/store/crossCoverage/actions/fetchCrossCoverages'
import { getCrossCoverages } from 'app/fieldapp/store/crossCoverage/selectors/getCrossCoverages'
import { getCrossCoverageDrawerState } from 'app/fieldapp/store/ui/selectors'

import { UpcomingCoverageEvents } from './UpcomingCoverageEvents'

export type UpcomingCoverageEventsProps = {
  isEditingEvent: boolean
}

export const UpcomingCoverageEventsContainer: React.FC<UpcomingCoverageEventsProps> =
  React.memo(({ isEditingEvent }) => {
    const dispatch = useDispatch()
    const coverageEvents = useSelector(getCrossCoverages)

    const crossCoverageDrawerState = useSelector(getCrossCoverageDrawerState)

    // We use "isEditingEvent" to determine if we want to show all upcoming coverage events (used on the ProfilePage)
    // or the filtered events (those on the CrossCoverageDrawer) which exclude the event that was clicked on
    const displayedCoverageEvents = coverageEvents.filter(coverageEvent => {
      if (!isEditingEvent) return true
      return coverageEvent.id !== crossCoverageDrawerState.crossCoverageId
    })

    const [fetchCrossCoveragesState, fetchCrossCoveragesFn] =
      useAsyncFnWithReset(async () => {
        return dispatch(fetchCrossCoverages({}))
      }, [dispatch])

    useMount(() => {
      fetchCrossCoveragesFn()
    })

    return (
      <UpcomingCoverageEvents
        coverageEvents={displayedCoverageEvents}
        isError={Boolean(fetchCrossCoveragesState.error)}
        isLoading={fetchCrossCoveragesState.loading}
      />
    )
  })
