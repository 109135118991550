import { createAsyncThunk } from '@reduxjs/toolkit'

import { HkEmployeeType } from 'packages/grimoire'
import { createDateObject } from 'packages/utils/dateHelpers'
import { logError } from 'packages/wiretap/logging'

import { getFrozenCleans } from '../../cleans/selectors'
import { fetchActiveCleanTimesForUser } from '../../cleantimes/actions'
import { fetchActiveOtherTimersForUser } from '../../otherTimers/actions'
import { fetchReviewsByUnitIdAndDateSince } from '../../reviews/actions/fetchReviewsByUnitId'
import { getReviewDateSince } from '../../reviews/reviews.utils'
import { fetchActiveTicketTimesForUser } from '../../ticket-times/actions'
import { getUnits } from '../../units/selectors/getUnits'
import { getActiveUser } from '../../users/selectors'
import { getVisitsOnDate } from '../../visits/selectors'

export const fetchSecondaryDataBatch = createAsyncThunk(
  'app/fetchSecondaryDataBatch',
  async (_, { dispatch, getState, rejectWithValue }) => {
    const activeUser = getActiveUser(getState())
    const frozenCleans = getFrozenCleans(getState())
    const visits = getVisitsOnDate(getState(), createDateObject())
    const unitIds = getUnits(getState())

    try {
      if (!activeUser) {
        throw new Error('No active user found')
      }

      const promises: Promise<unknown>[] = [
        dispatch(fetchActiveOtherTimersForUser()),
        dispatch(fetchActiveTicketTimesForUser()),
        dispatch(
          fetchReviewsByUnitIdAndDateSince(unitIds, getReviewDateSince()),
        ),
      ]

      const frozenCleanIds = frozenCleans.map(clean => clean.id)
      if (
        (frozenCleanIds.length || !!visits.length) &&
        activeUser?.employeeType === HkEmployeeType.employee
      ) {
        promises.push(dispatch(fetchActiveCleanTimesForUser()))
      }

      await Promise.all(promises)
    } catch (err) {
      logError(err)
      rejectWithValue(err)
    }
  },
)
