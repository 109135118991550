import styled from '@emotion/styled'
import * as React from 'react'

import { Checkbox, CheckboxProps } from './Checkbox'

const CheckboxListContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-top: 24px;
`

export type LabelValuePair = [string, string]

type PickedCheckboxProps = Pick<CheckboxProps, 'onChange'>

export type CheckboxListProps = PickedCheckboxProps & {
  /**
   * The options sets to pass to the checkboxes
   * [0] will be used for the label text
   * [1] will be used for the value
   */
  options: LabelValuePair[]
}

/**
 * A wrapper component for rendering a vertical list of Checkboxes with
 * some sane default stying baked in.
 */
export const CheckboxList: React.FC<CheckboxListProps> = React.memo(
  ({ onChange, options }) => {
    return (
      <CheckboxListContainer>
        {options.map(([label, value]) => (
          <Checkbox
            key={value}
            label={label}
            onChange={onChange}
            value={value}
          />
        ))}
      </CheckboxListContainer>
    )
  },
)
