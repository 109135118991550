import { keys, map, pipe } from 'lodash/fp'
import React from 'react'
import { useDispatch } from 'react-redux'

import { OfflineCleanTime, OfflineOtherTimer } from 'packages/grimoire'
import { OfflinePatchData } from 'packages/offline'
import { useOnlineStatus } from 'packages/utils/hooks'

import { OfflineTicketTime } from 'app/fieldapp/store/ticket-times'

import {
  fetchCleanTimeById,
  reloadOfflineCleanTimes,
} from '../cleantimes/actions'
import {
  fetchOtherTimerById,
  reloadOfflineOtherTimers,
} from '../otherTimers/actions'
import {
  fetchTicketTimeById,
  reloadOfflineTicketTimes,
} from '../ticket-times/actions'
import { offlineTimers } from './timers'
import { rejectOfflineOnlyTimers } from './useOfflineData.helpers'

type UseOfflineData = {
  reloadOfflineData: () => Promise<void>
}

export const useOfflineData = (): UseOfflineData => {
  const dispatch = useDispatch()
  const isOnline = useOnlineStatus().isOnline()

  /**
   * Sends a fetch[type]ById request for every timer currently stored in offline timers.
   * Note that this will only do anything when offline. We are kind of taking advantage
   * of PWA caching here, in that making these requests is effectively the only way
   * to force the ServiceWorker to reload these from cache.
   *
   * This is necessary to ensure we handle a somewhat edge case, e.g.
   * - User starts timer online
   * - User goes offline, stops/submits timer
   * - User does a full page/app refresh without going back online
   *
   * In this case, the "new" timer would not have been cached with the full "fetch all"
   * request yet, but it will have been cached in the "fetch by ID" request. So re-firing
   * these requests in this specific case will let us reload the original data offline.
   */
  const refetchCachedOfflineTimers = React.useCallback(async () => {
    if (isOnline) return

    /*
     * Creates an Array of promises which dispatches "action" for each item in "timers"
     * Use this to build a set of promises for a given timer set for use with Promise.all()
     */
    const buildPromises = (
      action,
      timers: Record<string, OfflinePatchData<unknown>>,
    ) =>
      pipe(
        keys,
        map(id => dispatch(action(id))),
      )(timers)

    const cleanTimes = await offlineTimers.getCleanTimes()
    const otherTimers = await offlineTimers.getOtherTimers()
    const ticketTimes = await offlineTimers.getTicketTimes()

    const otherTimersWithApiData =
      rejectOfflineOnlyTimers<OfflineOtherTimer>(otherTimers)

    const cleanTimesWithApiData =
      rejectOfflineOnlyTimers<OfflineCleanTime>(cleanTimes)

    const ticketTimesWithApiData =
      rejectOfflineOnlyTimers<OfflineTicketTime>(ticketTimes)

    const cleanTimePromises = buildPromises(
      fetchCleanTimeById,
      cleanTimesWithApiData,
    )
    const otherTimerPromises = buildPromises(
      fetchOtherTimerById,
      otherTimersWithApiData,
    )
    const ticketTimePromises = buildPromises(
      fetchTicketTimeById,
      ticketTimesWithApiData,
    )

    await Promise.all([
      ...cleanTimePromises,
      ...otherTimerPromises,
      ...ticketTimePromises,
    ])
  }, [dispatch, isOnline])

  /**
   * Restores any offline data saved in our local cache to Redux. Each individual "section"
   * of offline data should be loaded into the associated section of Redux under
   * an "offlineData" object, keyed by ID.
   */
  const reloadOfflineData = React.useCallback(async () => {
    await refetchCachedOfflineTimers()
    await Promise.all([
      dispatch(reloadOfflineOtherTimers()),
      dispatch(reloadOfflineCleanTimes()),
      dispatch(reloadOfflineTicketTimes()),
    ])
  }, [dispatch, refetchCachedOfflineTimers])

  return {
    reloadOfflineData,
  }
}
