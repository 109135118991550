import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import { withMaintenanceMode } from 'packages/common'

import { BadDelegateLink } from './core/components'
import { ConnectedProfilePage } from './profile'
import { ScheduleIndex } from './schedule'
import {
  SHARED_CLEAN_INVALID_URL,
  SHARED_CLEAN_ROOT_URL,
} from './schedule/schedule.utils'
import { SharedCleanPageContainer } from './schedule/SharedCleanPage'
import { TicketsIndex } from './tickets/TicketsIndex'

export type FieldAppRouterProps = {
  initialUrl: string
}

/**
 * Top-level routes for viewing shared cleans with delegate tokens.
 * This is the experience for non-user contractors with temporary auth via delegate tokens.
 * All routes here have /shared/ prefix in the URL.
 */
const DelegateTokenRoutes = () => {
  return (
    <Switch>
      <Route component={BadDelegateLink} path={SHARED_CLEAN_INVALID_URL} />
      <Route
        component={SharedCleanPageContainer}
        path={SHARED_CLEAN_ROOT_URL}
      />
      <Redirect to={SHARED_CLEAN_INVALID_URL} />
    </Switch>
  )
}

/** Standard top-level Field App routes. These define the "default" experience for regular users. */
const StandardRoutes: React.FC<FieldAppRouterProps> = ({ initialUrl }) => {
  return (
    <Switch>
      <Route component={ScheduleIndex} path="/cleans" />
      <Route component={TicketsIndex} path="/tickets" />
      <Route component={ConnectedProfilePage} path="/profile" />
      <Redirect from="/oauth/callback" to={initialUrl || '/cleans'} />
      <Redirect exact={true} to="/cleans" />
    </Switch>
  )
}

const FieldAppRouter: React.FC<FieldAppRouterProps> = ({ initialUrl }) => {
  return (
    <Switch>
      <Route component={DelegateTokenRoutes} path="/shared" />
      <StandardRoutes initialUrl={initialUrl} />
    </Switch>
  )
}

export default withMaintenanceMode(FieldAppRouter)
