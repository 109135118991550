import { createSelector } from 'reselect'

import { ApplicationState } from 'app/fieldapp/store/store'

import { VisitDrawerState } from '../ui.types'

export const getVisitDrawerState = createSelector(
  (state: ApplicationState) => state.ui.drawers.visitDrawer,
  (unitDrawerState: VisitDrawerState) => unitDrawerState,
)
