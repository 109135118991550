import { noop } from 'lodash/fp'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useMount, usePrevious } from 'react-use'

import { createDateObject, addWeeks } from 'packages/utils/dateHelpers'
import { useAsyncFnWithReset, useOnlineStatus } from 'packages/utils/hooks'

import { fetchCoverageByDateRange } from 'app/fieldapp/store/coverage/actions'
import { ApplicationState } from 'app/fieldapp/store/store'

import {
  generateWorkWeekDateRange,
  getDateBoundsFromWorkWeek,
} from '../AvailabilitySchedule.utils'
import { WeekCalendar } from './WeekCalendar'

export const WeekCalendarContainer: React.FC = React.memo(() => {
  const isOnline = useOnlineStatus().isOnline()
  const dispatch = useDispatch()

  /**
   * The exact date does matter here - this is for the date that is shown in the detail view
   *
   * For now this and selectedWeek will always be the same, but in the future this will likely diverge
   * */
  const [selectedDate, setSelectedDate] = React.useState<Date>(
    createDateObject(),
  )

  /** The exact day doesn't particularly matter, this is used to track the week in the calendar cards  */
  const [selectedWeek, setSelectedWeek] = React.useState<Date>(
    createDateObject(),
  )

  const mayRequireFetch = useSelector(
    (state: ApplicationState) => state.coverage.isStale,
  )

  const dateRange = generateWorkWeekDateRange(selectedWeek)

  const dateBounds: [string, string] = React.useMemo(
    () => getDateBoundsFromWorkWeek(dateRange),
    [dateRange],
  )

  const [fetchCoverageState, fetchCoverageFn] =
    useAsyncFnWithReset(async () => {
      return dispatch(
        fetchCoverageByDateRange({
          dateRange: dateBounds,
        }),
      )
    }, [dateBounds, dispatch])

  useMount(() => {
    fetchCoverageFn()
  })

  const previousWeekStartDate = usePrevious(dateBounds[0])

  // Refetch if the date range changes
  React.useEffect(() => {
    if (
      !!previousWeekStartDate &&
      previousWeekStartDate !== dateBounds[0] &&
      !fetchCoverageState.loading
    ) {
      fetchCoverageFn()
    }
  }, [
    previousWeekStartDate,
    dateBounds,
    fetchCoverageFn,
    fetchCoverageState.loading,
  ])

  // Refetch if a change has been made externally that may change computed coverage
  React.useEffect(() => {
    if (mayRequireFetch && !fetchCoverageState.loading) {
      fetchCoverageFn()
    }
  }, [mayRequireFetch, fetchCoverageFn, fetchCoverageState.loading])

  const changeSelectedWeek = (direction: 'inc' | 'dec') => () => {
    if (!isOnline) return noop
    setSelectedWeek(prevDate =>
      addWeeks(prevDate, direction === 'inc' ? 1 : -1),
    )

    setSelectedDate(prevDate => {
      const newWeek = addWeeks(prevDate, direction === 'inc' ? 1 : -1)
      const dateRange = generateWorkWeekDateRange(newWeek)
      return dateRange[0] // pick the first day of the week we navigate to
    })
  }

  const handleCardClick = (date: Date) => {
    setSelectedDate(date)
  }

  return (
    <WeekCalendar
      changeSelectedWeek={changeSelectedWeek}
      dateRange={dateRange}
      error={fetchCoverageState.error}
      handleCardClick={handleCardClick}
      isOnline={isOnline}
      loading={fetchCoverageState.loading}
      selectedDate={selectedDate}
    />
  )
})
