import { useHistory } from 'react-router-dom'

import { MissingDataError } from 'packages/utils/errors'
import { logError } from 'packages/wiretap/logging'

import { Slugs, useI18n } from 'app/fieldapp/i18n'
import { useAppDispatch } from 'app/fieldapp/store/hooks'
import { TicketStatusError } from 'app/fieldapp/store/tickets'
import { setBanner } from 'app/fieldapp/store/ui/actions'

type UseTicketsError = {
  handleError: (error: Error) => void
}

export function useTicketsError(): UseTicketsError {
  const { t } = useI18n()
  const history = useHistory()
  const dispatch = useAppDispatch()

  function handleTicketIdDoesNotExist(error: MissingDataError) {
    history.replace(`/tickets`)
    dispatch(
      setBanner({ message: t(Slugs.errorTicketNotFound), timeout: 5000 }),
    )

    logError(error)
  }

  function handleTicketStatusError(error: TicketStatusError) {
    dispatch(
      setBanner({ message: t(Slugs.anErrorOccurredLong), timeout: 5000 }),
    )

    logError(error)
  }

  function handleError(error: Error) {
    if (error instanceof MissingDataError) {
      handleTicketIdDoesNotExist(error)
    } else if (error instanceof TicketStatusError) {
      handleTicketStatusError(error)
    } else {
      /* If we don't have an explicit plan to handle the error,
        we throw the error, and allow the SentryErrorBoundary do it's thing */
      throw error
    }
  }

  return { handleError }
}
