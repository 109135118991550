import React from 'react'

import { isViewingSharedClean } from 'app/fieldapp/components/schedule/schedule.utils'
import { Slugs, useI18n } from 'app/fieldapp/i18n'
import { Clean } from 'app/fieldapp/store/cleans'

import { InspectionChecklistContainer } from '../../InspectionChecklist'
import { InspectionChecklistProps } from '../../InspectionChecklist/InspectionChecklist'
import { TaskDetailWrapperStyles } from '../../TaskSharedStyles'
import { CleanDetail, CleanDetailProps } from '../CleanDetail'
import { createShareText } from '../CleanDetail.helpers'
import { scrollToDrawerTop } from '../CleanDetailDrawer/CleanDetailDrawer.utils'
import { ShareCleanButtonContainer } from '../components/ShareCleanButton'

export const St = {
  ...TaskDetailWrapperStyles,
}

const useTranslations = (clean: Clean) => {
  const { t, tp } = useI18n()

  return {
    inspection: t(Slugs.inspection),
    sharedTitle: `Clean for ${clean.unit?.name || t(Slugs.unknown)}`,
    shareText: createShareText(clean, t, tp),
    unitInfo: t(Slugs.unitInfo),
  }
}

type TabType = 'unitInfo' | 'inspection'

type PickedCleanDetailProps = Pick<
  CleanDetailProps,
  'clean' | 'lockbox' | 'smartLock'
>

type PickedInspectionChecklistProps = Pick<
  InspectionChecklistProps,
  'isLoadingClean'
>

export type CleanDetailWrapperProps = PickedCleanDetailProps &
  PickedInspectionChecklistProps & {
    onCloseDrawerClick: () => void
  }

export const CleanDetailWrapper: React.FC<CleanDetailWrapperProps> = React.memo(
  ({ clean, isLoadingClean, lockbox, onCloseDrawerClick, smartLock }) => {
    const strings = useTranslations(clean)
    const shouldRenderHeaderSection = !isViewingSharedClean()

    const [activeTab, setActiveTab] = React.useState<TabType>('unitInfo')
    const selectTab = React.useCallback(
      (newTab: TabType) => () => {
        setActiveTab(newTab)
        scrollToDrawerTop()
      },
      [],
    )

    return (
      <>
        <St.Header>
          {shouldRenderHeaderSection && (
            <St.HeaderControls>
              <ShareCleanButtonContainer clean={clean} />
              <St.CloseButton onClick={onCloseDrawerClick} />
            </St.HeaderControls>
          )}

          <St.TabWrapper>
            <St.Tab
              active={activeTab === 'unitInfo'}
              onClick={selectTab('unitInfo')}
            >
              {strings.unitInfo}
            </St.Tab>

            <St.Tab
              active={activeTab === 'inspection'}
              onClick={selectTab('inspection')}
            >
              {strings.inspection}
            </St.Tab>
          </St.TabWrapper>
        </St.Header>

        {activeTab === 'unitInfo' ? (
          <CleanDetail clean={clean} lockbox={lockbox} smartLock={smartLock} />
        ) : (
          <InspectionChecklistContainer
            clean={clean}
            isLoadingClean={isLoadingClean}
          />
        )}
      </>
    )
  },
)
