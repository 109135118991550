import { useDecision } from '@optimizely/react-sdk'
import * as React from 'react'
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'

import { Features } from 'packages/optimizely'

import styles from './MaintenancePage.module.scss'

export type MaintenancePageProps = {
  message?: string
  title?: string
}

export const MaintenancePage: React.FC<MaintenancePageProps> = ({
  title = 'Application currently unavailable',
  message,
}) => (
  <div className={styles.content}>
    <div className={styles.contentWrapper}>
      <div className={styles.contentTitle}>{title}</div>

      <div className={styles.detail}>
        {!!message && <div>{message}</div>}

        <div className={styles.reload}>
          <a href="/">Click here to reload</a>
        </div>
      </div>
    </div>
  </div>
)

export const MaintenanceMode: React.FC<MaintenancePageProps> = props => (
  <BrowserRouter>
    <Switch>
      <Route
        component={() => <MaintenancePage {...props} />}
        path="/maintenance"
      />
      <Redirect to="/maintenance" />
    </Switch>
  </BrowserRouter>
)

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const withMaintenanceMode = WrappedComponent => props => {
  const [decision] = useDecision(Features.MAINTENANCE, { autoUpdate: true })

  return decision.enabled ? (
    <MaintenanceMode {...decision.variables} />
  ) : (
    <WrappedComponent {...props} />
  )
}
