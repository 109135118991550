import { createSelector } from 'reselect'

import { ApplicationState } from 'app/fieldapp/store/store'

import { OnCallTaskDrawerState } from '../ui.types'

export const getOnCallTaskDrawerState = createSelector(
  (state: ApplicationState) => state.ui.drawers.onCallTaskDrawer,
  (onCallTaskDrawerState: OnCallTaskDrawerState) => onCallTaskDrawerState,
)
