import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useToast } from 'packages/common'
import { useManageConnectedDrawerState } from 'packages/common/src/modals/Drawer/hooks/useManageConnectedDrawerState'
import { createDateString } from 'packages/utils/dateHelpers'
import { useAsyncFnWithReset } from 'packages/utils/hooks'
import { JsonApiErrorResponse } from 'packages/utils/store'

import { createCrossCoverage } from 'app/fieldapp/store/crossCoverage/actions/createCrossCoverage'
import { fetchCrossCoverages } from 'app/fieldapp/store/crossCoverage/actions/fetchCrossCoverages'
import { setCrossCoverageDrawer } from 'app/fieldapp/store/ui/actions'
import { getCrossCoverageDrawerState } from 'app/fieldapp/store/ui/selectors'
import { getActiveUser } from 'app/fieldapp/store/users/selectors'

import { useCrossCoverageDrawerTranslations } from '../../../../useCrossCoverageDrawerTranslations'
import {
  FormValidationError,
  makeCrossCoveragePayload,
} from '../../../../utils/CrossCoverageState.helpers'
import { CrossCoverageFormContainerProps } from '../../../../utils/prop.types'
import { CrossCoverageFormState } from '../../../state/CrossCoverageForm.reducer'
import { CrossCoverageFormControls } from '../CrossCoverageFormControls'
import { useValidation } from './validation.utils'

export const getSubmitIsDisabled = (state: CrossCoverageFormState): boolean => {
  const isErrorFree = state.formValidationError === undefined
  const hasRepeatingValue = state.isRepeating !== null
  const hasSelectedAssignee = state.selectedAssignee !== undefined
  const hasRepeatDays = state.isRepeating === false || state.repeatDays?.length

  return !(
    isErrorFree &&
    hasRepeatingValue &&
    hasSelectedAssignee &&
    hasRepeatDays
  )
}

export const NewCrossCoverageFormControlsContainer: React.FC<
  CrossCoverageFormContainerProps
> = ({ formDispatch, formState }) => {
  const strings = useCrossCoverageDrawerTranslations()
  const activeUser = useSelector(getActiveUser)
  const dispatch = useDispatch()

  const drawerManager = useManageConnectedDrawerState({
    dispatchableToggleDrawerAction: setCrossCoverageDrawer,
    selector: getCrossCoverageDrawerState,
  })

  const { showToast } = useToast()

  const validateFormState = useValidation({
    activeUser,
    changeType: 'create',
    formDispatch,
  })

  const [handleSubmitButtonState, handleSubmitButtonClick] =
    useAsyncFnWithReset(async () => {
      if (!validateFormState(formState) || !activeUser?.id) {
        return
      }

      return dispatch(
        createCrossCoverage({
          callbacks: {
            onError: (error: JsonApiErrorResponse) => {
              return formDispatch({
                payload: new FormValidationError(
                  error.response?.data?.errors?.[0]?.detail ||
                    strings.createCrossCoverageFailure,
                ),
                type: 'setFormValidationError',
              })
            },
            onSuccess: () => {
              showToast({
                message: strings.createCrossCoverageSuccess,
                toastType: 'success',
              })

              formDispatch({
                payload: null,
                type: 'changeIsRepeatingAndReset',
              })

              dispatch(
                fetchCrossCoverages({
                  startDateGte: createDateString(),
                }),
              )

              drawerManager.closeDrawer()
            },
          },
          postData: makeCrossCoveragePayload(formState, activeUser.id),
        }),
      )
    }, [
      activeUser,
      dispatch,
      drawerManager,
      formDispatch,
      formState,
      showToast,
      strings.createCrossCoverageFailure,
      strings.createCrossCoverageSuccess,
      validateFormState,
    ])

  return (
    <CrossCoverageFormControls
      crossCoverageId={formState.crossCoverageId}
      handleSubmitButtonClick={handleSubmitButtonClick}
      handleCancelButtonClick={drawerManager.closeDrawer}
      isLoading={handleSubmitButtonState.loading}
      submitIsDisabled={getSubmitIsDisabled(formState)}
    />
  )
}
