import styled from '@emotion/styled'
import React, { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Loader } from 'packages/common'
import { isFuture, isPast } from 'packages/utils/dateHelpers'
import { useAsyncFnWithReset } from 'packages/utils/hooks'

import { getReservationById } from 'app/fieldapp/store/reservations/selectors'
import { ApplicationState } from 'app/fieldapp/store/store'
import { fetchTicketById } from 'app/fieldapp/store/tickets/actions'
import { Unit } from 'app/fieldapp/store/units'

import { UnitOccupancyDetails } from './UnitOccupancyDetails'

const St = {
  LoaderWrapper: styled.div`
    margin: 16px 0 16px 0;
    position: relative;
    width: 100%;
  `,
}

export type UnitOccupancyDetailsContainerProps = {
  ticketId: string
  unit: Unit
}

export const UnitOccupancyDetailsContainer: React.FC<UnitOccupancyDetailsContainerProps> =
  React.memo(({ unit, ticketId }) => {
    const dispatch = useDispatch()

    const nextResId = unit.nextResId || ''
    const currentResId = unit.currentResId || ''

    const [fetchTicketByIdState, fetchTicketByIdFn] = useAsyncFnWithReset(
      async (ticketId: string) => {
        return dispatch(fetchTicketById(ticketId))
      },
      [dispatch],
    )

    React.useEffect(() => {
      fetchTicketByIdFn(ticketId)
    }, [fetchTicketByIdFn, ticketId])

    const nextReservation = useSelector((state: ApplicationState) =>
      getReservationById(state, nextResId),
    )

    const currentReservation = useSelector((state: ApplicationState) =>
      getReservationById(state, currentResId),
    )

    const isUnitOccupied = useMemo(() => {
      if (!currentReservation) return false
      return (
        isFuture(currentReservation.checkOut) &&
        isPast(currentReservation.checkIn)
      )
    }, [currentReservation])

    return fetchTicketByIdState.loading ? (
      <St.LoaderWrapper>
        <Loader />
      </St.LoaderWrapper>
    ) : (
      <UnitOccupancyDetails
        isUnitOccupied={isUnitOccupied}
        hasUnitFamily={!!unit.unitFamilyIds?.length}
        reservation={isUnitOccupied ? currentReservation : nextReservation}
      />
    )
  })
